import {ProfitcentreComponent} from './masters/profitcentre/profitCenter.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ChartOfAccountComponent} from '../app/masters/chartofaccount/chartofaccount.component';
import {CompanyComponent} from '../app/masters/company/company.component';
import {DashSubCardComponent} from './dash-sub-card/dash-sub-card.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SystemSettingsComponent} from './system-settings/system-settings.component';
import {IncotermComponent} from '../app/masters/incoterm/incoterm.component';
import {ContractbookComponent} from './masters/contractbook/contractbook.component';
import {PortfolioComponent} from './masters/portfolio/portfolio.component';
import {UsermasterComponent} from './masters/usermaster/usermaster.component';

import {ChatofaccountdetailsComponent} from './masters/chartofaccount/chatofaccountdetails/chatofaccountdetails.component';
import {GlobalIndicatorsComponent} from './masters/global-indicators/global-indicators.component';
import {GlobalIndicatorDetailsComponent} from './masters/global-indicators/global-indicator-details/global-indicator-details.component';
import {LoginpageComponent} from './loginpage/loginpage.component';
import {SignupComponent} from './signup/signup.component';
import {RolemasterComponent} from './masters/rolemaster/rolemaster.component';
import {LocationComponent} from './masters/location/location.component';
import {UnitOfMeasurementComponent} from './masters/unit-of-measurement/unit-of-measurement.component';
import {CommodityComponent} from './masters/commodity/commodity.component';
import {BrandmasterComponent} from './masters/brandmaster/brandmaster.component';
import {CropseasonComponent} from './masters/cropseason/cropseason.component';
import {GrademasterComponent} from './masters/grademaster/grademaster.component';
import {GradequalityspecComponent} from './masters/grademaster/gradequalityspec/gradequalityspec.component';
import {BrandqualityspecComponent} from './masters/brandmaster/brandqualityspec/brandqualityspec.component';
import {ProfitCenterDetailsComponent} from './masters/profitcentre/profit-center-details/profit-center-details.component';
import {TransactionreferenceComponent} from './masters/transactionreference/transactionreference.component';
import {PriceindexComponent} from './masters/priceindex/priceindex.component';
import {SecondarycostComponent} from './masters/secondarycost/secondarycost.component';
import {CalendarmasterComponent} from './masters/calendarmaster/calendarmaster.component';
import {PaymenttermComponent} from './masters/paymentterm/paymentterm.component';
import {PaymenteventComponent} from './masters/paymentterm/paymentevent/paymentevent.component';
import {CounterpartyComponent} from './masters/counterparty/counterparty.component';
import {ContractComponent} from './physicals/contract/contract.component';
import {TradeComponent} from './physicals/contract/trade/trade.component';
import {PlanningComponent} from './physicals/planning/planning.component';
import {TradeObligationComponent} from './Operations/trade-obligation/trade-obligation.component';
import {RbacService} from './services/validators/rbac.service';

import {CashforwardComponent} from './physicals/cashforward/cashforward.component';
import {TradeAllocationComponent} from './Operations/trade-allocation/trade-allocation.component';
import {FxPlanningComponent} from './Operations/fx-planning/fx-planning.component';
import {TradeActualizationComponent} from './Operations/trade-actualization/trade-actualization.component';
import {InventoryComponent} from './Operations/inventory/inventory.component';
import {StockPlanningComponent} from './Operations/stock-planning/stock-planning.component';
import {CashFlowComponent} from './Finance/cash-flow/cash-flow.component';
import {TradeCashFlowComponent} from './Finance/trade-cash-flow/trade-cash-flow.component';
import {SettlementComponent} from './Finance/settlement/settlement.component';
import {InvoicesAndPaymentsComponent} from './Finance/invoices-and-payments/invoices-and-payments.component';
import {EodComponent} from './Risk/eod/eod.component';
import {MtmMappingComponent} from './Risk/eod/mtm-mapping/mtm-mapping.component';
import {PriceFixationComponent} from './Operations/price-fixation/price-fixation.component';
import {FutureCommodityPriceComponent} from './Risk/future-commodity-price/future-commodity-price.component';
import {PhysicalCommodityPriceComponent} from './Risk/physical-commodity-price/physical-commodity-price.component';
import {BasisCommodityPriceComponent} from './Risk/basis-commodity-price/basis-commodity-price.component';
import {FxCommodityPriceComponent} from './Risk/fx-commodity-price/fx-commodity-price.component';
import {DocumentTemplateMasterComponent} from './masters/document-template-master/document-template-master.component';
import {PriceAllocationComponent} from './Operations/price-allocation/price-allocation.component';
import {FutureNettingComponent} from './Operations/future-netting/future-netting.component';
import {ExternalPackagingComponent} from './masters/external-packaging/external-packaging.component';
import {InternalPackagingComponent} from './masters/internal-packaging/internal-packaging.component';
import {PointOfSaleComponent} from './Operations/point-of-sale/point-of-sale.component';
import {CounterpartyLimitsComponent} from './Risk/counterparty-limits/counterparty-limits.component';
import {MarketLimitsComponent} from './Risk/market-limits/market-limits.component';
import {ApiConfigComponent} from './masters/api-config/api-config.component';
import {ChartOfAccountsComponent} from './masters/chart-of-accounts/chart-of-accounts.component';
import {TransportAllocationComponent} from './Operations/transport-allocation/transport-allocation.component';
import {OpenFutureDashboardComponent} from './dashboard/open-future-dashboard/open-future-dashboard.component';
import {TransportActualizationComponent} from './Operations/transport-actualization/transport-actualization.component';
import {OperationsDashboardComponent} from './Operations/operations-dashboard/operations-dashboard.component';
import {XcelerIframeComponent} from './Components/xceler-iframe/xceler-iframe.component';
import {CostComponent} from './Finance/cost/cost.component';
import {ApprovalConfigurationComponent} from './masters/approval-configuration/approval-configuration.component';
import {NotificationSettingsComponent} from './masters/notification-settings/notification-settings.component';
import {ApprovalEditComponent} from './masters/approval-configuration/approval-edit/approval-edit.component';
import {ApproverWorkflowComponent} from './masters/approver-workflow/approver-workflow.component';
import {FutureTradeComponent} from './physicals/future-trade/future-trade.component';
import {PdfRpaComponent} from './pdf-rpa/pdf-rpa.component';
import {RpaResultComponent} from './pdf-rpa/rpa-result/rpa-result.component';
import {TenantConfigurationComponent} from './masters/tenant-configuration/tenant-configuration.component';
import {FutureManagementDashboardComponent} from './Risk/future-management-dashboard/future-management-dashboard.component';
import {FutureCommodityPriceV1Component} from './Risk/future-commodity-price-v1/future-commodity-price-v1.component';
import {TransportBookingComponent} from './Operations/transport-booking/transport-booking.component';
import {
  AllocationMatchingComponent
} from "./Operations/price-allocation/allocation-matching/allocation-matching.component";

const routes: Routes = [
  {path: 'company', component: CompanyComponent, canActivate: [RbacService]},
  {path: 'chartofaccounts', component: ChartOfAccountComponent, canActivate: [RbacService]},
  {path: 'chartofaccountsdetails/:id', component: ChatofaccountdetailsComponent, canActivate: [RbacService]},
  {path: 'subCard', component: DashSubCardComponent, canActivate: [RbacService]},
  {path: 'dashboard', component: DashboardComponent},
  {path: '', component: DashboardComponent},
  {path: 'system_settings', component: SystemSettingsComponent, canActivate: [RbacService]},
  {path: 'incoterm', component: IncotermComponent, canActivate: [RbacService]},
  {path: 'profitCenters', component: ProfitcentreComponent, canActivate: [RbacService]},
  {path: 'portfolio', component: PortfolioComponent, canActivate: [RbacService]},
  {path: 'usermaster', component: UsermasterComponent, canActivate: [RbacService]},
  {path: 'globalIndicators', component: GlobalIndicatorsComponent, canActivate: [RbacService]},
  {path: 'globalIndicatorDetails/:id', component: GlobalIndicatorDetailsComponent, canActivate: [RbacService]},
  {path: 'contract_book', component: ContractbookComponent, canActivate: [RbacService]},
  {path: 'login', component: LoginpageComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'rolemaster', component: RolemasterComponent, canActivate: [RbacService]},
  {path: 'location', component: LocationComponent, canActivate: [RbacService]},
  {path: 'uom', component: UnitOfMeasurementComponent, canActivate: [RbacService]},
  {path: 'commodity', component: CommodityComponent, canActivate: [RbacService]},
  {path: 'brandmaster', component: BrandmasterComponent, canActivate: [RbacService]},
  {path: 'cropseason', component: CropseasonComponent, canActivate: [RbacService]},
  {path: 'grademaster', component: GrademasterComponent, canActivate: [RbacService]},
  {path: 'gradeQualitySpec/:id', component: GradequalityspecComponent, canActivate: [RbacService]},
  {path: 'brandQualitySpec/:id', component: BrandqualityspecComponent, canActivate: [RbacService]},
  {path: 'profitCenterConfig/:id', component: ProfitCenterDetailsComponent, canActivate: [RbacService]},
  {path: 'transactionReference', component: TransactionreferenceComponent, canActivate: [RbacService]},
  {path: 'priceIndex', component: PriceindexComponent, canActivate: [RbacService]},
  {path: 'secondaryCost', component: SecondarycostComponent, canActivate: [RbacService]},
  {path: 'calendarMaster', component: CalendarmasterComponent, canActivate: [RbacService]},
  {path: 'paymentTerm', component: PaymenttermComponent, canActivate: [RbacService]},
  {path: 'paymentEvent/:id', component: PaymenteventComponent, canActivate: [RbacService]},
  {path: 'counterparty', component: CounterpartyComponent, canActivate: [RbacService]},
  {path: 'contractMain', component: ContractComponent, canActivate: [RbacService]},
  {path: 'trade', component: TradeComponent, canActivate: [RbacService]},
  {path: 'paperTrade', component: TradeComponent, canActivate: [RbacService]},
  {path: 'interCompanyTrade', component: TradeComponent, canActivate: [RbacService]},
  {path: 'planning', component: PlanningComponent, canActivate: [RbacService]},
  {path: 'tradeObligation', component: TradeObligationComponent, canActivate: [RbacService]},
  {path: 'cashForward', component: CashforwardComponent, canActivate: [RbacService]},
  {path: 'tradeAllocation', component: TradeAllocationComponent, canActivate: [RbacService]},
  {path: 'fxPlanning', component: FxPlanningComponent, canActivate: [RbacService]},
  {path: 'tradeActualization', component: TradeActualizationComponent, canActivate: [RbacService]},
  {path: 'storageInventory', component: InventoryComponent, canActivate: [RbacService]},
  {path: 'stockPlanning', component: StockPlanningComponent, canActivate: [RbacService]},
  {path: 'debitcreditnote', component: TradeCashFlowComponent, canActivate: [RbacService]},
  {path: 'paymentInstruction', component: TradeCashFlowComponent, canActivate: [RbacService]},
  {path: 'cashFlow', component: CashFlowComponent, canActivate: [RbacService]},
  {path: 'settlement', component: SettlementComponent, canActivate: [RbacService]},
  {path: 'invoices', component: InvoicesAndPaymentsComponent, canActivate: [RbacService]},
  {path: 'tradeCashFlow', component: TradeCashFlowComponent, canActivate: [RbacService]},
  {path: 'eod', component: EodComponent, canActivate: [RbacService]},
  {path: 'mtm', component: MtmMappingComponent, canActivate: [RbacService]},
  {path: 'priceFixing', component: PriceFixationComponent, canActivate: [RbacService]},
  {path: 'priceAllocation',component:AllocationMatchingComponent,canActivate: [RbacService]},
  {path: 'futureCommodityPrice', component: FutureCommodityPriceComponent, canActivate: [RbacService]},
  {path: 'physicalCommodityPrice', component: PhysicalCommodityPriceComponent, canActivate: [RbacService]},
  {path: 'basisCommodityPrice', component: BasisCommodityPriceComponent, canActivate: [RbacService]},
  {path: 'fxCommodityPrice', component: FxCommodityPriceComponent, canActivate: [RbacService]},
  {path: 'priceAllocation', component: PriceAllocationComponent, canActivate: [RbacService]},
  {path: 'documentTemplateMaster', component: DocumentTemplateMasterComponent, canActivate: [RbacService]},
  {path: 'futureNetting', component: FutureNettingComponent, canActivate: [RbacService]},
  {path: 'externalPackaging', component: ExternalPackagingComponent, canActivate: [RbacService]},
  {path: 'internalPackaging', component: InternalPackagingComponent, canActivate: [RbacService]},
  {path: 'futureNetting', component: FutureNettingComponent, canActivate: [RbacService]},
  {path: 'pos', component: PointOfSaleComponent, canActivate: [RbacService]},
  {path: 'cpLimits', component: CounterpartyLimitsComponent, canActivate: [RbacService]},
  {path: 'marketLimits', component: MarketLimitsComponent, canActivate: [RbacService]},
  {path: 'apiconfig', component: ApiConfigComponent, canActivate: [RbacService]},
  {path: 'chartOfAccounts', component: ChartOfAccountsComponent, canActivate: [RbacService]},
  {path: 'transportAllocation', component: TransportAllocationComponent, canActivate: [RbacService]},
  {path: 'openFutureDashboard', component: OpenFutureDashboardComponent},
  {path: 'transportActualization', component: TransportActualizationComponent, canActivate: [RbacService]},
  {path: 'operationsDashboard', component: OperationsDashboardComponent,canActivate: [RbacService]},
  {path: 'positionExposure', component: XcelerIframeComponent,canActivate: [RbacService]},
  {path: 'cost', component: CostComponent, canActivate: [RbacService]},
  {path: 'charges', component: CostComponent, canActivate: [RbacService]},
  {path: 'approvalconfiguration', component: ApprovalConfigurationComponent, canActivate: [RbacService]},
  {path: 'notificationsettings', component: NotificationSettingsComponent, canActivate: [RbacService]},
  {path: 'approverworkflow', component: ApproverWorkflowComponent},
  {path: 'approvalupdate', component: ApprovalEditComponent},
  {path: 'futuretrade', component: FutureTradeComponent, canActivate: [RbacService]},
  {path: 'tenantconfig', component: TenantConfigurationComponent, canActivate: [RbacService]},
  {path: 'futuretrade', component: FutureTradeComponent, canActivate: [RbacService]},
  {path: 'pdfrpa', component: PdfRpaComponent, canActivate: [RbacService]},
  {path: 'pdfrpa-view', component: RpaResultComponent, canActivate: [RbacService]},
  {path: 'futureManagementDashboard', component: FutureManagementDashboardComponent, canActivate: [RbacService]},
  {path: 'futurePrice', component: FutureCommodityPriceV1Component},
  {path: 'transportBooking', component: TransportBookingComponent, canActivate: [RbacService]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
