import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonService} from "../services/common.service";
import {environment} from "../../environments/environment";
import {FormControl, FormGroup} from "@angular/forms";
import {MessageService} from "primeng";

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.css']
})
export class ManageProfileComponent implements OnInit {
  userDetails: any[];
  showDialog: boolean = true;
  sideMenuList: any[] = [];
  countryList: any[] = [];
  activeMenu: any;
  signaturePreview: string = '';
  userData: FormGroup;
  @Output() onClose = new EventEmitter();
  title: string[] = [];
  showPassword: boolean = true;

  constructor(private commonService: CommonService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.getCountryList();
    this.sideMenuList = [{title: 'My Profile'}, {title: 'My Organisation'}, {title: 'Notifications'}, {title: 'My Digital Signature'}];
    this.activeMenu = 'My Profile';
    this.getUserData();
  }

  getMenuClass(menu: any) {
    if (menu['title'] === this.activeMenu) {
      return 'activeMenu'
    } else {
      return 'menuItem'
    }
  }

  onClickMenu(menu: any) {
    this.activeMenu = menu['title'];
  }

  onSignKeyPress(keyPress) {
  }

  onCloseEmit(close) {
    this.onClose.emit(close);
  }

  getUserData() {
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url_new + '/api-iam/api/userProfile/v1/getUserDetails?tenantId=' + this.commonService.getFromStorage('tenantId') + '&userId=' + this.commonService.getFromStorage('userName')).subscribe((next: any) => {
      _this.userDetails = next;
      _this.initForm();
      _this.getListTitle(next['userInfoData']);
    });
  }

  getListTitle(object: any) {
    this.title = Object.keys(object);
  }

  initForm() {
    this.userData = new FormGroup({
      firstName: new FormControl(this.userDetails['userMasterData']['firstName']),
      middleName: new FormControl(this.userDetails['userMasterData']['middleName']),
      lastName: new FormControl(this.userDetails['userMasterData']['lastName']),
      address: new FormControl(this.userDetails['userMasterData']['address']),
      country: new FormControl(this.userDetails['userMasterData']['country']),
      telephone: new FormControl(this.userDetails['userMasterData']['telephone']),
      password: new FormControl(this.userDetails['userMasterData']['password']),
      email: new FormControl(this.userDetails['userMasterData']['emailAddress']),
      signature: new FormControl()
    });
  }

  getCountryList() {
    this.countryList = this.commonService.getCountryList();
  }

  getAvatarLetter(userName: string) {
    return userName.charAt(0).toUpperCase();
  }

  onClickUpdate() {
    let userProfilePayload: {};
    userProfilePayload = {
      userName: this.userDetails['userMasterData']['userName'],
      address: this.userData.controls['address'].value,
      country: this.userData.controls['country'].value,
      telephone: this.userData.controls['telephone'].value,
      password: this.userData.controls['password'].value,
      emailAddress: this.userData.controls['email'].value
    };
    this.commonService.post(environment.base_url_new + '/api-iam/api/usermanagementservice/v1/updateUserDetails', userProfilePayload).subscribe((next) => {
      this.messageService.add({
        key: 'toastMessage',
        severity: 'info',
        summary: 'Success',
        detail: 'User Profile Updated'
      });
    });
  }
}
