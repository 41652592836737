<login-new (onClickLogin)="onClickLogin()" *ngIf="showLanding"></login-new>
<div *ngIf="!showLanding">
  <header [hidden]="!checkLoginStatus()">
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top navbarDiv">
      <svg (click)="toggle=!toggle" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4073_81263)">
          <rect opacity="0.1" width="40" height="40" rx="4" fill="white"/>
          <circle cx="12" cy="12" r="2" fill="#F3F4FF"/>
          <circle cx="20" cy="12" r="2" fill="#F3F4FF"/>
          <circle cx="12" cy="20" r="2" fill="#F3F4FF"/>
          <circle cx="20" cy="20" r="2" fill="#F3F4FF"/>
          <circle cx="28" cy="12" r="2" fill="#F3F4FF"/>
          <circle cx="28" cy="20" r="2" fill="#F3F4FF"/>
          <circle cx="12" cy="28" r="2" fill="#F3F4FF"/>
          <circle cx="20" cy="28" r="2" fill="#F3F4FF"/>
          <circle cx="28" cy="28" r="2" fill="#F3F4FF"/>
        </g>
        <defs>
          <clipPath id="clip0_4073_81263">
            <rect width="40" height="40" fill="white"/>
          </clipPath>
        </defs>
      </svg>

      <div *ngIf="toggle" class="switch-main menu" (mouseleave)="toggle = false" >
        <div class="switch-div">
          <div class="switch-header">APPLICATIONS</div>
          <div class="switch-container">
           <ng-container *ngFor="let app of applications">
            <div *ngIf="app.isActive"  (click)="switchApp(app['value'])" (mouseover)="onmouseoverapp(app)" (mouseout)="onmouseoutapp(app)" [ngClass]="{'selected-application':app.selected}" class="switch-item">
                <img id="{{app['value']}}" class="switch-tick" src="../assets/images/svg/switch-tick.svg" >
                <span [ngClass]="{'selected-header':app.selected}" class="switch-span">{{app['label']}}</span>
            </div>
           </ng-container>
          </div>
        </div>
      </div>
      <div class="d-inline-flex">
      <img [routerLink]="['/dashboard']" alt="User Profile" class="img-fluid z-depth-1 animated fadeIn brandLogo"
           data-toggle="tooltip" src="../assets/XcelerV2Logo.png"
           title="Profile" style="padding-right: 10px;">
        <div style="color:white; border-left: 1px solid white; padding-left: 10px; padding-top:7px;font-family: 'Raleway', sans-serif;font-size:18px;font-weight: 600;" > CTRM</div>
      </div>

      <button aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation"
              class="navbar-toggler"
              data-target="#basicExampleNav" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse navClass" id="basicExampleNav">
        <ul class="navbar-nav mr-auto navUL">
          <li class="nav-item liClass">
            <a class="nav-link navLinkCustom" routerLink="/dashboard">Dashboard
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li *ngFor="let mapkey of mapKeys" class="nav-item dropdown liClass">
            <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle navLinkCustom"
               (mouseover)="onmouseover('navbarDropdownMenuLink1',mapkey['id'])"
               data-toggle="dropdown" id="navbarDropdownMenuLink1">{{mapkey['title']}}
            </a>
            <div [id]="mapkey['id']" (inViewportAction)="calculateLeft(mapkey['id'])"
                 [style]="{left:menuLeft}"
                 aria-labelledby="navbarDropdownMenuLink1" class="dropdown-menu mega-menu v-2 z-depth-1 megamenuDiv"
                 inViewport>
              <div class="row ml-2">
                <div *ngFor="let megamenu of breadCrumbService.getFormMegaMenu(mapkey['key'])" class="mb-xl-0 mb-4 mt-2 listContainer"
                     style="width: max-content">
                  <h6 class="sub-title text-uppercase font-weight-bold megaMenuTitle">{{megamenu.title}}</h6>
                  <ul class="list-unstyled megamenuUL">
                    <li *ngFor="let item of megamenu.items" class="megaMenuLI">
                      <a *ngIf="item.route === '/deal-slip'" (click)="isRouteEnabled(item)?goToTradeOptions(item):''" [ngClass]="activemenucss(item)" class="menu-item pl-0">
                        <img [src]="this.commonService.getIconPath(item['icon'])" class="masterIcons">{{item['name']}}
                      </a>
                      <a *ngIf="item.route !== '/deal-slip'" [href]="isRouteEnabled(item)?(item['switchApp']!== null &&
                       item['switchApp']!== undefined && item['switchApp'])?item.context+'/#' + item.route+getParams(item):'/#'+item.route+getParams(item):''" [ngClass]="activemenucss(item)" class="menu-item pl-0">
                        <img [src]="this.commonService.getIconPath(item['icon'])" class="masterIcons">{{item['name']}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div [hidden]="!islogin" class="flexDisplay">

          <div class="toolContainer">
            <div *ngIf="isDeveloperMode" style="display: inline-flex">
              <button style="white-space: nowrap" (click)="copyToClipboard()">Copy Local Storage</button>
              <button style="white-space: nowrap" (click)="pasteToClipboard()">Paste Local Storage</button>
            </div>
            <div *ngIf="checkForNotificationBell()" class="toolIcon">
              <div *ngIf="newNotif" class="badgeCircle"></div>
              <img pTooltip="Notifications" src="../assets/icons/bell.svg" tooltipZIndex="99999" (click)="openNotificationsPanel($event)">
            </div>
            <img class="toolIcon" pTooltip="NeedHelp?" src="../assets/icons/questionMark.svg" tooltipZIndex="99999">
            <img class="toolIcon" pTooltip="Bookmarks" src="../assets/icons/bookmark.svg" tooltipZIndex="99999">
            <img class="toolIcon" pTooltip="Settings" src="../assets/images/svg/settings.svg" (click)="openSettings($event)" tooltipZIndex="99999">

            <!--USER SETTINGS DIALOG-->
            <div *ngIf ="showSettings" class="box arrow-top" (click)="$event.stopPropagation()" (mouseleave)="showSettings = false">
              <div class="master-header">MASTERS</div>
              <div>
                <!-- <div class="master-section" (click)="switchApp('business-master')">
                  <span class="master-sub">Business Masters</span>
                  <p class="h6 master-desp">Primary Data Configuration for Business Operations such as Company, Counterparty etc.</p>
                </div> -->
                <div class="master-section" (click)="switchApp('business-master-config')">
                  <span class="master-sub">Business Masters</span>
                  <p class="h6 master-desp">Primary Data Configuration for Business Operations such as Company,Counterparty etc.</p>
                </div>
                <div class="master-section" [ngClass]="!systemSettingActive?'item-disabled':''" (click)="systemSettingActive?switchApp('system-setting'):''">
                  <span class="master-sub">System Settings</span>
                  <p class="h6 master-desp">System-Level Configuration such as user creation,role access,tenant Configuration etc.</p>
                </div>
                <div class="master-section" [ngClass]="!systemSettingActive?'item-disabled':''" (click)="systemSettingActive?switchApp('system-setting-beta'):''">
                  <span class="master-sub">System Settings-Beta</span>
                  <p class="h6 master-desp">System-Level Configuration such as user creation,role access,tenant Configuration etc.</p>
                </div>
                <div class="master-section">
                  <span class="master-sub">Support</span>
                  <p class="h6 master-desp">For queries, reach out to support@xceler.ai</p>
                </div>
              </div>
            </div>

            <div (click)="openUserMenu($event)" class="userInfoBox">
              <p class="accountPic" pTooltip="{{name}}" tooltipPosition="bottom"
                 tooltipZIndex="99999">{{nametoshow}}</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <div [ngClass]="islogin?'withnav':'withoutnav'">
    <div [ngClass]="!visval?'collapseSB':'expandSB'" style="overflow-y: hidden; overflow-x: hidden">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!--  DEAL SLIP DIALOG-->
  <deal-slip (closeEvent)="closeModal($event)" (onSave)="onSave($event)" *ngIf="modalState"
             [modalStateDeal]="true"></deal-slip>

  <!--USER PROFILE MANAGEMENT SCREEN-->
  <app-manage-profile (onClose)="closeManageProfile()" *ngIf="modalUserProfile"></app-manage-profile>
</div>

<!--NOTIFICATION PANEL-->
<div class="notificationContainer style-4" *ngIf="notifPanelDisplay" (click)="$event.stopPropagation()">
  <div *ngIf="!notificationLoadingFlag">
    <notification-and-alert (onActionClick)="onActionClick($event)" [notifications]="allNotifications"></notification-and-alert>
  </div>

  <div class="notifLoadingWrapper" *ngIf="notificationLoadingFlag">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<!--USER MENU DIALOG-->
<div *ngIf ="showLogout" class="userPanel" (click)="$event.stopPropagation()" (mouseleave)="showLogout = false">
  <div (click)="openUserProfile()" class="ripple">
    <i class="fas fa-cog iconButton"></i>
    <p>Manage Your account</p>
  </div>
  <div (click)="logout()" class="ripple">
    <i class="fas fa-arrow-right iconButton"></i>
    <p>Log Out</p>
  </div>
</div>

<p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}" appendTo="body"
         key="toastMessage"></p-toast>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js" type="text/javascript"></script>
<!-- Bootstrap tooltips -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.4/umd/popper.min.js"
        type="text/javascript"></script>
<!-- Bootstrap core JavaScript -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/js/bootstrap.min.js"
        type="text/javascript"></script>
<!-- MDB core JavaScript -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.8.9/js/mdb.min.js"
        type="text/javascript"></script>
