<p-dialog #ds (onHide)="onCloseEmit($event)" [(visible)]="showDialog"
          [baseZIndex]="25"
          [closeOnEscape]="false" [draggable]="false" [maximizable]="false"
          [modal]="true"
          [style]="{ width: '65%',height: '95%',maxHeight:'85%', padding:'0px', background:'white'}">
  <p-header [style]="{fontSize:'20px'}">
    Manage Profile
  </p-header>
  <div class="mainWrapper">

    <!--      Left-->
    <div class="leftWrapper">
      <div *ngIf="userDetails" class="accPicWrapper">
        <div class="avatarWrapper">
          <p class="accountPic">{{getAvatarLetter(userDetails['userMasterData']['userName'])}}</p>
        </div>
        <div class="nameWrapper">
          <p class="userName">{{userDetails['userMasterData']['userName']}}</p>
          <p class="userRole" pTooltip="{{userDetails['userMasterData']['role']}}" tooltipZIndex="9999" tooltipPosition="bottom">{{userDetails['userMasterData']['role']}}</p>
        </div>
      </div>
      <div class="sideMenuWrapper">
        <div (click)="onClickMenu(menu)" *ngFor="let menu of sideMenuList" [ngClass]="getMenuClass(menu)">
          <p>{{menu['title']}}</p>
        </div>
      </div>
    </div>


    <!--      Right-->
    <div class="rightWrapper">
      <div [hidden]="activeMenu !== 'My Profile'" class="myProfileWrapper">
        <p class="sectionHeading">Account Details</p>

        <form *ngIf="userDetails !== undefined && userDetails !== null" [formGroup]="userData">
          <div class="formWrapper">
            <div class="formField">
              <p class="fieldHeader">First Name</p>
              <input [attr.disabled]="true" class="form-control form-control-sm" formControlName="firstName" pInputText>
            </div>

            <div class="formField">
              <p class="fieldHeader">Middle Name</p>
              <input [attr.disabled]="true" class="form-control form-control-sm" formControlName="middleName"
                     pInputText>
            </div>

            <div class="formField">
              <p class="fieldHeader">Last Name</p>
              <input [attr.disabled]="true" class="form-control form-control-sm" formControlName="lastName" pInputText>
            </div>
          </div>

          <div class="formWrapper">
            <div class="formField">
              <p class="fieldHeader">Telephone</p>
              <input class="form-control form-control-sm" formControlName="telephone" pInputText>
            </div>

            <div class="formField">
              <p class="fieldHeader">Email</p>
              <input class="form-control form-control-sm" formControlName="email" pInputText>
            </div>
          </div>

          <div class="formWrapper">
            <div class="formField">
              <p class="fieldHeader">Address</p>
              <input class="form-control form-control-sm" formControlName="address" pInputText>
            </div>

            <div class="formField">
              <p class="fieldHeader">Country</p>
              <p-dropdown [options]="countryList" formControlName="country"></p-dropdown>
            </div>
          </div>

          <div class="formWrapper">
            <div class="formField" style="width: 50%">
              <p class="fieldHeader">Password</p>
              <div style="display: flex">
                <input [attr.type]="showPassword?'password':'text'" class="form-control form-control-sm" formControlName="password"
                       pInputText>
                <i (click)="showPassword = !showPassword" [ngClass]="showPassword?'fa-eye':'fa-eye-slash'"
                   class="fas" style="padding-left: 10px; padding-top: 10px"></i>
              </div>
            </div>
          </div>
        </form>

        <div class="btnWrapper">
          <button (click)="onClickUpdate()" class="buttonClass">Update</button>
        </div>
      </div>


      <!--      My Organisation Section-->
      <div [hidden]="activeMenu !== 'My Organisation'" class="myOrgWrapper">

        <div class="listWrapper">
          <div *ngFor="let title of title" class="listBox">
            <p class="listHeading animated fadeInUp">{{title}}</p>
            <div *ngFor="let userDetail of userDetails['userInfoData'][title]"
                 class="listItem">{{userDetail['masterTypeValue']}}</div>
          </div>
        </div>
      </div>


      <!--      My Difgital Signature-->
      <div [hidden]="activeMenu !== 'My Digital Signature'" class="signatureWrapper">
        <p-tabView>
          <p-tabPanel header="Text">
            <div class="formField">
              <p class="fieldHeader">Enter Name:</p>
              <input (change)="onSignKeyPress($event)" [(ngModel)]="signaturePreview" class="form-control form-control-sm"
                     pInputText style="margin-top: 10px">
            </div>

            <div class="signPreviewWrapper">
              <p class="previewHeading">Signature Preview: </p>
              <div class="previewBox">
                <p class="preView">{{signaturePreview}}</p>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Draw">
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
  <p-toast [baseZIndex]="9999" [style]="{marginTop: '80px',minWidth:'500px', whiteSpace: 'pre-line'}"
           key="toastMessage"></p-toast>
</p-dialog>

