import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {KeyValue} from '../grid/key-value.model';
import {environment} from '../../environments/environment';
import {messages} from './common/messages';
import {AllValidationErrors} from '../grid/grid.component';
import {FormGroup, ValidationErrors} from '@angular/forms';
import {DatePipe, DecimalPipe, TitleCasePipe} from '@angular/common';
import {filter} from '../masters/services/filter.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MasterService} from '../masters/services/MasterService';
import {Grid} from '../grid/grid.model';
import {
  GlobalIndicatorDetailsComponent
} from '../masters/global-indicators/global-indicator-details/global-indicator-details.component';
import {Tcolumn} from '../grid/tcolumn.model';
import {BreadcrumbService} from '../Components/ctrm-breadcrumb/breadcrumb.service';
import {entities} from './common/entities';
import {Filter} from '../grid/preference/filter/filter.model';
import {ConfirmationService, MenuItem, Table} from 'primeng';
import {FilterUtils} from 'primeng/utils';
import {ajax} from 'rxjs/ajax';
import {DomSanitizer} from '@angular/platform-browser';
import {ToWords} from 'to-words';
import {Interceptor} from './interceptor';
import {FileSaverService} from 'ngx-filesaver';
import {Button} from '../grid/buttons.model';
import {ExpressionSolverService} from './ExpressionSolver.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  obj: Object = {};
  currencyList: any[] = [];
  countryList: any[] = [];
  timeZoneList: any[] = [];
  userSystemTimeZoneId: string;
  systemOffset: any;
  commonObject: any = {};
  myRoles: string[] = [];
  datePipe: DatePipe;
  myLoginType: string = 'user';
  countryMap: Map<string, any>;
  currencyObject: any;
  titleCasePipe: TitleCasePipe;
  megaMenuMap: Map<string, any>;
  MASTER_MAP_KEY = 'Master Settings';
  TRADE_MAP_KEY = 'Trade';
  OPERATION_MAP_KEY = 'Operations';
  RISK_MAP_KEY = 'Risk';
  FINANCE_MAP_KEY = 'Finance';
  Inventory_MAP_KEY= 'Inventory';
  currencyJson:any = null;
  private _currentRoute = '';
  months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


  constructor(private interceptor: Interceptor,
              private sanitizer: DomSanitizer,
              private http: HttpClient,
              private messageService: MessageService,
              private router: ActivatedRoute,
              private fileSaverService: FileSaverService,
              private route: Router,
              private injector: Injector, private decimalPipe: DecimalPipe,private confirmationService: ConfirmationService) {
    this.datePipe = new DatePipe('en-US');
    this.getTimeZoneList();
    //this.getMyRoles();
    this.updateCurrencyJson();
    this.createFilters();
    this.titleCasePipe = new TitleCasePipe();
  }

  getMomentDate(date:any= new Date()) {
    return moment(date).utc(true).toDate();
  }

  moment(date:any= new Date()) {
    return moment(date);
  }


  get currentRoute(): string {
    return this._currentRoute;
  }

  set currentRoute(value: string) {
    this._currentRoute = value;
  }

  private _myRoleString: string = '';

  get myRoleString(): string {
    return this._myRoleString;
  }

  set myRoleString(value: string) {
    this._myRoleString = value;
  }

  private _play: boolean = false;

  get play(): boolean {
    return this._play;
  }

  set play(value: boolean) {
    this._play = value;
  }

  combineArray(array1: any[], array2: any[]) {
    return [...array1, ...array2];
  }

  getMonthNumber(month) {
    return this.months.indexOf(month);
  }

  applyTitleCaseFormatting(value: any) {
    return this.titleCasePipe.transform(value);
  }

  applyTitleCaseFormattingIfNotUpper(value: any) {
    let spl:any[] = value.split(' ');
    let filter = spl.filter((i:string) => i.charAt(0) == i.charAt(0).toLowerCase());
    let correct = spl.filter((i:string) => i.charAt(0) == i.charAt(0).toUpperCase());
    return this.applyTitleCaseFormatting(filter.join(" ")) + ' '+ correct.join(" ");
  }


  /**
   * check for value is Empty or not
   * @param value
   */
  isEmpty(value) {
    return (value === null || value === undefined || value.toString().length === 0);
  }

  getFromTenantConfig(defValue,...keys) {
    try {
      let tenantConfig = JSON.parse(this.getFromStorage("tenantConfig"));
      let root = Object.assign({}, tenantConfig);
      keys.forEach((key) => {
        root = root[key];
      })
      if(root === null || root === undefined) {
        return defValue;
      }
      return root;
    } catch (e) {
      return defValue;
    }
  }

  getRoundingFormat(format:string,defaultValue:number = 2) {
      return this.getFromTenantConfig(defaultValue,"roundingFormat",format);
  }

  getRowDataFromFile(columns: Tcolumn[], reader: FileReader) {
    let _this = this;
    let csv: any = reader.result;
    let lines = csv.split(/\r|\n|\r/);
    let headers = lines[0].split(',');
    let newLines = [];
    for (let i = 1; i < lines.length; i++) {
      if (lines[i] !== undefined && lines[i] !== null && lines[i].length > 0) {
        let data = lines[i].split(',');
        let rowData = {};
        columns.forEach(function (col: Tcolumn) {
          let index = 0;
          if (headers.includes('"' + col.getHeader() + '"') || headers.includes('"')) {
            index = headers.indexOf('"' + col.getHeader() + '"');
          } else {
            index = headers.indexOf(col.getHeader());
          }
          rowData[col.getField()] = _this.getRowValue(data[index].replace(new RegExp('"', 'g'), ''), col);
        });
        newLines.push(rowData);
      }
    }
    return newLines;
  }

  /**
   * check for value is undefined or null or none
   * @param value
   */
  isNullorNone(value) {
    return (value !== null && value !== undefined && value !== 'none' && value.toString().length > 0);
  }

  getLoadErrorMessage(error: HttpErrorResponse) {
    let message = "";
    if (error.message.includes("Http failure response")) {
      message = 'Http Failure Response (Error Status : ' + error.status + ')';
    } else {
      message = 'Something went wrong !';
    }
    return message;
  }

  public saveToObject(key, value) {
    this.commonObject[key] = value;
  }

  public getFromObject(key) {
    return this.commonObject[key];
  }

  getUserSystemTimeZoneId() {
    return this.userSystemTimeZoneId;
  }

  save(key, value) {
    this.obj[key] = value;
  }

  get(key) {
    return this.obj[key];
  }

  saveToStorage(key, value) {
    localStorage.setItem(key, value);
  }

  getFromStorage(key): any {
    return localStorage.getItem(key);
  }

  getTenantId(){
    return this.getFromStorage('tenantId');
  }

  clearStorage() {
    localStorage.clear();
  }

  public getJSONByFile(filename): Observable<any> {
    return this.http.get('../assets/json/' + filename);
  }

  getMonthList() {
    let list = [];
    list.push(new KeyValue('Select', ''));
    list.push(new KeyValue('Jan', 1));
    list.push(new KeyValue('Feb', 2));
    list.push(new KeyValue('Mar', 3));
    list.push(new KeyValue('Apr', 4));
    list.push(new KeyValue('May', 5));
    list.push(new KeyValue('Jun', 6));
    list.push(new KeyValue('Jul', 7));
    list.push(new KeyValue('Aug', 8));
    list.push(new KeyValue('Sep', 9));
    list.push(new KeyValue('Oct', 10));
    list.push(new KeyValue('Nov', 11));
    list.push(new KeyValue('Dec', 12));
    return list;
  }

  addInExtra(previousExtraValue: any, key: string, value: any) {
    previousExtraValue[key] = value;
    return previousExtraValue;
  }

  public getJSONByURL(url: string) {
    // url = url.replace(' ', '%20');
    return this.http.get(url);
  }
  public deletByURL(url: string) {
    // url = url.replace(' ', '%20');
    return this.http.delete(url);
  }


  public getJSONByObject(url: string, object: any) {
    return this.http.post(url, object);
  }

  public post(url: string, object: any) {return this.http.post(url, object);
  }

  public postWithProgress(url: string, object: any) {return this.http.post(url, object,{ reportProgress:true,observe: "events" });
  }

  public patch(url: string, object: any) {
    return this.http.patch(url, object);
  }

  removeOtherKeys(data) {
    delete data.id;
    delete data.createdBy;
    delete data.updatedBy;
    delete data.createdTimestamp;
    delete data.updatedTimestamp;
    delete data.startDate;
    delete data.endDate;
    delete data.tenantId;
    delete data._links;
    return data;
  }

  postMessage(title: string, message: string, type: string = 'info', duration: number = 5000) {
    this.messageService.add({
      severity: type, summary: (title !== null && title !== undefined && title.length > 0) ? title + ' \n' : undefined,
      detail: message, life: duration
    });
  }

  confirmDialog(message:string,onAccept:Function,onReject?:Function) {
    this.confirmationService.confirm({
      message: message,
      accept: () => {
        if(onAccept)
              onAccept();

      }, reject: () => {
        if(onReject)
            onReject();
      }
    });
  }

  getMonthsList() {
    return this.months;
  }

  async getDataByURLAsync(url: string) {
    return await this.http.get(url).toPromise();
  }

  formatOffset(offset) {
    let sp = offset.split('.');
    let first = sp[0];
    let second = parseInt(sp[1]);
    let newoffset = first[0];
    first = first.substr(1, first.length - 1);
    if (first < 10) {
      newoffset += '0' + first;
    } else {
      newoffset += '' + first;
    }
    if (second < 10) {
      newoffset += ':0' + second;
    } else {
      newoffset += ':' + second;
    }
    return newoffset;
  }

  getDfor(num) {
    let str = '';
    for (let i = 0; i < num; i++) {
      str += 'd';
    }
    return str;
  }

  decimalPatters(beforePoint: number, afterPoint: number) {
  }

  getErrorMessage(messageString: string, fieldName: any, otherPara: any[] = []) {
    messageString = messageString.replace('<fieldname>', fieldName);
    otherPara.forEach(function (param, i) {
      messageString = messageString.replace('?' + (i + 1), param);
    });
    return messageString;
  }

  /**
   * take input a string from mail messages and apply fieldvalue and return a new string
   * @param messageString
   * @param otherPara
   */
  getMailMessage(messageString: string, otherPara: any[] = []) {
    otherPara.forEach(function (param, i) {
      messageString = messageString.replace('?' + (i + 1), param);
    });
    return messageString;
  }

   runFunction(value: any, callback) {
    if (typeof callback === 'function'){
      return callback(value);
    }
  }

  getValidationErrorMessage(formControl, header:string) {
    let errorMessage = '';
    if(formControl !== null && formControl !== undefined) {
      let errors: any[] = [];
      const controlErrors: ValidationErrors = formControl.errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
          errors.push({
            control_name: header,
            error_name: keyError,
            error_value: controlErrors[keyError]
          });
        });
      }
      errorMessage = this.extractErrors(errors);
    }
    return errorMessage;
  }

  extractErrors(errors: AllValidationErrors[]) {
    let text = '';
    if (errors != undefined && errors.length > 0) {
      for (let i = 0; i < errors.length; i++) {
        if (errors[i]) {
          switch (errors[i].error_name) {
            case 'required':
              text += this.getErrorMessage(messages.field_required.message, errors[i].control_name);
              break;
            case 'pattern':
              text += this.getErrorMessage(messages.wrong_pattern.message, errors[i].control_name);
              break;
            case 'email':
              text += this.getErrorMessage(messages.wrong_email.message, errors[i].control_name);
              break;
            case 'minlength':
              text += this.getErrorMessage(messages.min_length.message, errors[i].control_name, [errors[i].error_value.requiredLength]);
              break;
            case 'maxlength':
              text += this.getErrorMessage(messages.max_length.message, errors[i].control_name, [errors[i].error_value.requiredLength]);
              break;
            case 'areEqual':
              text += this.getErrorMessage(messages.are_equal.message, errors[i].control_name);
            case 'min':
              text += this.getErrorMessage(messages.min.message, errors[i].control_name, [errors[i].error_value.min]);
              break;
            case 'max':
              text += this.getErrorMessage(messages.max.message, errors[i].control_name, [errors[i].error_value.max]);
              break;
            case 'invalidDecimalLength':
              text += this.getErrorMessage(messages.invalidDecimalLength.message, errors[i].control_name, [errors[i].error_value.max]);
              break;
            case 'invalidIntegerLength':
              text += this.getErrorMessage(messages.invalidIntegerLength.message, errors[i].control_name, [errors[i].error_value.max]);
              break;
            case 'maxFileCountExceed':
              text += this.getErrorMessage(messages.maxFileCountExceed.message, errors[i].control_name, [errors[i].error_value.required, errors[i].error_value.found]);
              break;
            case 'invalidReferenceFormat':
              text += errors[i].error_value.message;
              break;
            default:
              text = errors[i].control_name + ' ' + errors[i].error_name + ' ' + ((typeof errors[i].error_value == 'object')?JSON.stringify(errors[i].error_value):errors[i].error_value);
          }
          text += '\n';
        }
      }
    }
    return text;
  }

  getLocalTimeForOffset(offset) {
    let d = new Date();
    let utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    let nd = new Date(utc + (3600000 * offset));
    return nd.toLocaleString();
  }

  getTimeZoneList() {
    this.systemOffset = this.getSystemTimeZone();
    if (this.timeZoneList.length > 0) {
      return this.timeZoneList;
    } else {
      let _this = this;
      this.timeZoneList.push(new KeyValue('Select', ''));
      this.getJSONByFile('timezone.json').subscribe(function (data) {
        let arr = data['cities']['city'];
        arr.forEach(function (city, i) {
          _this.timeZoneList.push(new KeyValue(city['name'] + ' (UTC ' + _this.formatOffset(city['offset']) + ')', city['name'] + ' (UTC ' + _this.formatOffset(city['offset']) + ')'));
          if (city['offset'] === _this.systemOffset) {
            if (_this.userSystemTimeZoneId === undefined) {
              _this.userSystemTimeZoneId = i + '';
            }
          }
        })
      });
      return this.timeZoneList;
    }
  }

  getCurrencyList(addSelect: boolean = true) {
    if (this.currencyList.length > 0) {
      return this.currencyList;
    } else {
      let _this = this;
      if (addSelect){
        this.currencyList.push(new KeyValue('Select', ''));
      }
      this.getJSONByFile('currency.json').subscribe(function (data) {
        _this.currencyObject = data;
        let keys = Object.keys(data);
        keys.forEach(function (key) {
          let obj = data[key];
          _this.currencyList.push(new KeyValue(key, key));
        })
      });

      return this.currencyList;
    }
  }

  getCurrencyListOnly() {
    if (this.currencyList.length > 0) {
      return this.currencyList;
    } else {
      let _this = this;
      this.getJSONByFile('currency.json').subscribe(function (data) {
          _this.currencyJson = data;
        let keys = Object.keys(data);
        keys.forEach(function (key) {
          let obj = data[key];
          _this.currencyList.push(new KeyValue(obj['code'] + ' (' + obj['symbol'] + ') ' + obj['name'], key));
        })
      });

      return this.currencyList;
    }
  }

  getCurrencyListbycodes(code: string[] = []) {
    let _this = this;
    let currencyList: KeyValue[] = [];
    currencyList.push(new KeyValue('Select', ''));
    this.getJSONByFile('currency.json').subscribe(function (data) {
      let keys = Object.keys(data);
      keys.forEach(function (key) {
        let obj = data[key];
        if (code.includes(obj['code'])) {
          currencyList.push(new KeyValue(obj['code'] + ' (' + obj['symbol'] + ') ' + obj['name'], key));
        }
      })
    });

    return currencyList;

  }


  getFromCountryMap(key: string) {
    if (this.countryMap == undefined) {
      this.getCountryList();
    }
    return this.countryMap.get(key);
  }

  getCountryList() {
    if (this.countryList.length > 0) {
      return this.countryList;
    } else {
      this.countryMap = new Map<string, any>();
      this.countryList.push(new KeyValue('Select', ''));
      let _this = this;
      this.getJSONByFile('country.json').subscribe(function (data) {
        data.forEach(function (country) {
          _this.countryList.push(new KeyValue(country['name'], country['alpha3Code']));
          _this.countryMap.set(country['alpha3Code'], country);
        })
      });
    }
    return this.countryList;
  }

  getTimeZoneListforalpha2(){
    var timezoneMap = new Map<string, any>();
    var timezoneList = [];
    timezoneList.push(new KeyValue('Select', ''));
    let _this = this;
    this.getJSONByFile('countrycodetimezone.json').subscribe(function (data) {
      data.forEach(function (timezone) {
        timezoneList.push(new KeyValue(timezone['TIMEZONE'], timezone['TIMEZONE']));
      })
    });
    return timezoneList;
  }

  getListFromUrl(url, key, value, addNone = false, addSelect = true) {
    let list = [];
    if (addSelect) {
      list.push(new KeyValue('Select', ''));
    }
    if (addNone) {
      list.push(new KeyValue('None', 'none'));
    }

    this.getJSONByURL(environment.base_url + url).subscribe(function (data: any) {
      if (data['_embedded']) {
        data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      } else {
        data.forEach(function (result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      }
    });
    return list;
  }

  getDirectListFromUrl(url, label: string = 'Select', value: string = '') {
    let _this = this;
    let list = [];
    this.getJSONByURL(environment.base_url + url).subscribe(function (data: any) {
      list = _this.addListOTT(data, label, value);
    });
    return list;
  }


  getListFromArray(data, key, value, addNone = false, addSelect = true) {
    let list = [];
    if (addSelect) {
      list.push(new KeyValue('Select', ''));
    }
    if (addNone) {
      list.push(new KeyValue('None', 'none'));
    }

    data.forEach(function (result) {
      if(result[value] !== null){
        list.push(new KeyValue(result[key], result[value]));
      }
    });
    return list;
  }

  getDefaultValueFromList(list: any[],defValue:string = '') {
    let defaultValue = defValue;
    if (list !== undefined && list !== null && list.length > 1) {
      defaultValue = list[1]['value'];
    }
    return defaultValue;
  }

  private getRequiredField(obj:any) {
      if(Array.isArray(obj)) {
        return obj.map(i => i.key);
      }
      return ['name'];
  }

  private getModelName(obj:any) {
    if(Array.isArray(obj) && obj.length > 0) {
      return obj[0]['value'];
    }
    return obj;
  }

  getLoadData(masterName, serviceNameArr, modelNameArr,conditionfunction:Function = null){
    let payload: any[] = [];
    let filterObj: any[] = [];
    serviceNameArr.forEach( (data, index) =>{
      let conditions:any[] = [];
      if(serviceNameArr[index] == 'data'){
        conditions = conditions.concat(modelNameArr[index]);
      }
      if(conditionfunction !== null && conditionfunction !== undefined ){
        conditions = conditions.concat(this.runFunction({serviceName:data,modelName:modelNameArr[index]},conditionfunction));
      }
      payload.push({
        tenantId: this.getFromStorage('tenantId'),
        serviceName: 'xceler_configservice',
        model:serviceNameArr[index] == 'data'?'globalindicatordetails':this.getModelName(modelNameArr[index]),
        requiredField: this.getRequiredField(modelNameArr[index]),
        filter: conditions.length > 0 ?  conditions : null
      })
    });

    return this.getJSONByObject(environment.base_url_new + '/api-bm/api/'+ masterName + '/v1/load', payload);
  }

  async getLoadDataAsync(masterName, serviceNameArr, modelNameArr){
    let payload: any[] = [];
    let filterObj: any[] = [];
    serviceNameArr.forEach( (data, index) =>{
      payload.push({
        tenantId: this.getFromStorage('tenantId'),
        serviceName: 'xceler_configservice',
        model:serviceNameArr[index] == 'data'?'globalindicatordetails':modelNameArr[index],
        requiredField:['name'],
        filter: serviceNameArr[index] == 'data'?modelNameArr[index]: null
      })
    });

    return await this.http.post(environment.base_url_new + '/api-bm/api/'+ masterName + '/v1/load', payload).toPromise();
  }

  getRequiredGiData(array: any[]) {
    return this.getJSONByObject(environment.base_url + '/globalIndicatorDetails/gidasc?tenantId='+this.getFromStorage('tenantId'), {name: array});
  }

  saveToUrl(url: string, resourceData: any): Observable<any[]> {
    return this.http.post<any[]>(url, resourceData)
      .map((response: any[]) => {
        return response;
      });
  }

  getByteArray(url: string, resourceData: any): Observable<any[]> {
    return this.http.post<any[]>(url, resourceData)
      .map((response: any[]) => {
        return response;
      });
  }

  getPostObject(key: any, value: any, entityname: any, operation: any = "equal", criteriaObj: any[] = [], rowData?: any, sortBy?: string[]) {
    let object = {};
    object['criteria'] = [{
      key: key,
      operation: operation,
      value: value
    }];
    let criteria = [];
    let objectCriteria = {};
    if (criteriaObj !== undefined && criteriaObj !== null && criteriaObj.length > 0) {
      criteriaObj.forEach(function (objectCriteriaTemp: any) {
        objectCriteria = {...objectCriteriaTemp};
        if (objectCriteria['value'] === undefined || objectCriteria['value'].length === 0) {
          objectCriteria['value'] = value;
        } else {
          if (typeof (objectCriteria['value']) === 'object' && objectCriteria['value'].length === 1 && rowData !== undefined) {
            objectCriteria['value'] = rowData[objectCriteria['value'][0]];
          }
        }
        criteria.push(objectCriteria);
      });
      object['criteria'] = criteria;
    }
    object['entityName'] = entityname;
    if (sortBy !== undefined && sortBy.length > 0) {
      object['sortBy'] = sortBy;
    }
    return object;
  }

  getDateValue(defaultValue: any) {
    if (typeof (defaultValue) === 'object') {
      return defaultValue;
    } else {
      if(defaultValue !== null && defaultValue !== undefined) {
        if (defaultValue.toString().length === 0) {
          return defaultValue;
        } else {
          return new Date(defaultValue);
        }
      }
      return defaultValue;
    }
  }

  getSystemTimeZone() {
    let date = new Date();
    let zone: string = '';
    let offset: any = -1 * (date.getTimezoneOffset() / 60);
    if (offset > 0) {
      zone = '+' + offset;
    }
    return zone;
  }

  saveUserProfile(info: {}) {
    this.saveToStorage('userid', info['userDetails']['uuid']);
    this.saveToStorage('id', info['userDetails']['id']);
    this.saveToStorage('tenantId', info['userDetails']['tenantId']);
    this.saveToStorage('userName', (info['userDetails']['username'] === null ||info['userDetails']['username'] === undefined)?info['userDetails']['userName']:info['userDetails']['username']);
    this.saveToStorage('email', info['userDetails']['emailAddress']);
    this.saveToStorage('contact', info['userDetails']['telephone']);
    this.saveToStorage('timezone', info['userDetails']['timeZone']);
    this.saveToStorage('country', info['userDetails']['country']);
    this.saveToStorage('roles', info['rbacdto']['roleNames']);
    this.saveToStorage('tenantConfig', (info['rbacdto']['tenantConfig'] !== null && info['rbacdto']['tenantConfig'] !== undefined) ? info['rbacdto']['tenantConfig'] : {});
    this.saveToStorage('TFrom', new Date());
    this.saveAccessPolicy(true, info['rbacdto']['accessPolicyJson']);
  }

  getFromUrl(url: string) {
    return this.http.get(url);
  }

  getFormattedDateTime(date, format = environment.dateFormat,useUTC:boolean = true) {
    if (date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
      return useUTC?this.datePipe.transform(date, format,'UTC'):this.datePipe.transform(date, format);
    }
    return '';
  }

  genrateRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * return the last date of given month
   * @param month
   * @param year
   * @param day
   */
  getLastDateOfMonth(month: number, year: number) {
    return new Date(year, month + 1, 0);
  }

  getLastDayOfMonth(date:Date) {
    date.setMonth(date.getMonth() + 1);
    date.setDate(0)
    return date;
  }

  saveUserMaster(info: any) {
    this.saveToStorage('userid', info['userDetails']['uuid']);
    this.saveToStorage('id', info['userDetails']['id']);
    this.saveToStorage('tenantId', info['userDetails']['tenantId']);
    this.saveToStorage('userName', info['userDetails']['firstName']);
    this.saveToStorage('email', info['userDetails']['emailAddress']);
    this.saveToStorage('contact', info['userDetails']['telephone']);
    // this.saveToStorage('company', info['userDetails']['companyName']);
    this.saveToStorage('timezone', info['userDetails']['timeZone']);
    this.saveToStorage('country', info['userDetails']['country']);
    this.saveToStorage('city', info['userDetails']['city']);
    this.saveToStorage('TFrom', new Date());
    this.saveToStorage('roles', info['rbacdto']['roleNames']);
    this.saveToStorage('tenantConfig', (info['rbacdto']['tenantConfig'] !== null && info['rbacdto']['tenantConfig'] !== undefined) ? info['rbacdto']['tenantConfig'] : {});
    this.saveAccessPolicy(true, info['rbacdto']['accessPolicyJson']);
  }

  //save RBAC access policy in the local storage by looping around the accessPolicy array
  saveAccessPolicy(menuGenerate = false, accessPolicy: any) {
    let _this = this;
    if (accessPolicy != null) {
      this.saveToStorage('policy', JSON.stringify(accessPolicy));
      this.generateReadAccessRouter(accessPolicy);
      //for breadcrumb
      let bs = this.injector.get(BreadcrumbService);
      if (menuGenerate) {
        bs.addMenu();
      }
    } else {
      _this.myLoginType = 'root';
    }
  }

  private generateReadAccessRouter(policy:string) {
    let json: any = {};
    if (typeof policy === 'string') {
      json = JSON.parse(policy);
    } else {
      json = policy;
    }
    var readAccessRouter: any = this.getFromStorage('readAccessRouter');
    if (readAccessRouter == null) {
      readAccessRouter = [];
    }
    if (typeof readAccessRouter == 'string') {
      readAccessRouter = readAccessRouter.split(',');
    }
    let keys: any[] = Object.keys(json);
    keys.forEach(function(key) {
      if (new ExpressionSolverService().setExpression(json[key]['Grid Access']['read'] + '').solve()) {
        readAccessRouter.push(key);
      }
    });
    this.saveToStorage('readAccessRouter', readAccessRouter);
  }

  getMyRolesString() {
    return this._myRoleString;
  }

  getMyRolesFrom(from: string[]) {
    let myRole = '';
    if (this.myRoles.length > 0) {
      this.myRoles.forEach(function (role) {
        if (from.includes(role)) {
          myRole = role;
        }
      });
    }
    return myRole;
  }

  isRoleFrom(from: string[]) {
    let isFrom: boolean = false;
    if (this.myRoles.length > 0) {
      this.myRoles.forEach(function (role) {
        if (from.includes(role.toLowerCase())) {
          isFrom = true;
        }
      });
    }
    return isFrom;
  }

  getMyLoginType() {
    return this.myLoginType;
  }

  getListFromPost(url: string, postObject: any, key: string, value: string, addNone: boolean = false, addSelect: boolean = true) {
    let list = [];
    if (!addNone) {
      list.push(new KeyValue('Select', ''));
    } else {
      list.push(new KeyValue('None', 'none'));
    }
    this.post(url, postObject).subscribe(function (data: any) {
      if (data['_embedded']) {
        data['_embedded'][Object.keys(data['_embedded'])[0]].forEach(function (result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      } else {
        data.forEach(function (result) {
          list.push(new KeyValue(result[key], result[value]));
        });
      }
    });
    return list;
  }

  navigateTo(path: string) {
    this.route.navigate([path]);
  }

  public getGIDColumns(value: any, masterCommonService: MasterService) {
    let grid: Grid;
    let gid = new GlobalIndicatorDetailsComponent(this.router, masterCommonService);
    gid.ngOnInit();
    grid = gid.grid;
    grid.getColumn().get('groupName').setDefaultValue(value);
    return grid;
  }

  getFistDateOfMonth(monthNumber: number, year: number) {
    return new Date(year, monthNumber, 1);
  }

  getFirstDayOfMonth(date:Date) {
    date.setDate(1);
    return date;
  }

  //This method returns the Object required as a payload to get Hierarchy
  public getHierarchyObject(selectedRow: any, code, entityName,namefield) {
    let obj = {
      "criteria": [
        {
          "key": "parent",
          "operation": "equal",
          "value": selectedRow[code]
        },
        {
          "key": "tenantId",
          "operation": "equal",
          "value": this.getFromStorage('tenantId')
        }
      ],
      "entityName": entityName,
      "nameField": namefield,
      "codeField": code
    };
    return obj;
  }

  getIconPath(iconName: string) {
    return '../assets/images/svg/' + iconName + '.svg';
  }

  getImagePath(iconName: string, extension: string = '.svg') {
    return '../assets/images/' + iconName + extension;
  }

  public fileSizeConvert(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  getUserInfoFilterObject(userId: string, masterType = 'Legal Entity | Profit Center | Counterparty | Commodity') {
    let legalEntityFilter = new filter(environment.base_url + 'filter', entities.userInfo);
    legalEntityFilter.addCriteria('masterType', masterType);
    legalEntityFilter.addCriteria('userId', userId);
    return legalEntityFilter;
  }

  checkAndGetValue(col: Tcolumn, rowData, viewOnly: boolean = false) {
    let value: any = !viewOnly ? '' : rowData[col.getField()];
    if (typeof col.getDefaultValue() === 'object' && col.getDefaultValue() !== undefined && col.getDefaultValue() !== null) {
      if (col.getDefaultValue()['formatNumber']) {
        value = this.getFormatedNumber(rowData[col.getField()], 'en-US');
      } else {
        if (col.getDefaultValue()['outputValueFormatfunction'] !== undefined) {
          let para = [rowData[col.getField()]];
          if (col.getDefaultValue()['outputValueFormatfunctionPara'] !== undefined && col.getDefaultValue()['outputValueFormatfunctionPara'].length > 0) {
            para = [];
            col.getDefaultValue()['outputValueFormatfunctionPara'].forEach(function(parameter) {
              para.push(rowData[parameter]);
            });
          }
          value = this.runFunction(para, col.getDefaultValue()['outputValueFormatfunction']);
        }
        if (col.getDefaultValue()['outputValueFieldName'] !== undefined && col.getDefaultValue()['outputValueFieldName'] !== null && col.getDefaultValue()['outputValueFieldName'].length > 0) {
          value = rowData[col.getDefaultValue()['outputValueFieldName']];
        }
      }
    }
    return value;
  }

  getDualSliderValue(rowDatum: any, col: Tcolumn, rowData) {
    if (typeof rowDatum === 'string') {
      rowDatum = JSON.parse(rowDatum);
    }
    let value = '';
    if (rowDatum !== undefined && rowDatum !== null) {
      value = '-' + rowDatum['min'] + ' - +' + rowDatum['max'];
    }
    return value;
  }

  getDualSliderValueGeneral(rowDatum: any, uom: string, type: string = 'percentage') {
    if (typeof rowDatum === 'string') {
      rowDatum = JSON.parse(rowDatum);
    }
    let suffix = type === 'percentage' ? '%' : uom;
    let value = '';
    if (rowDatum !== undefined && rowDatum !== null) {
      value = '- ' + rowDatum['min'] + suffix + ' - ' + '+' + rowDatum['max'] + suffix;
    }
    return value;
  }

  getFieldValue(field, rowData, formGroup: FormGroup) {
    if (formGroup !== undefined) {
      return formGroup.controls[field].value;
    } else if (rowData !== undefined) {
      return rowData[field];
    }
  }

  getDynamicPostObject(field: any, rowData: any, actionField: Tcolumn, formGroup?: FormGroup) {
    let postObject = {};
    let _this = this;
    if (field['postObjectFunction'] !== undefined) {
      let postObjectPara = [this.getFieldValue(actionField.getField(), rowData, formGroup)];
      if (field['postObjectFunctionPara'] !== undefined && field['postObjectFunctionPara'].length > 0) {
        postObjectPara = [];
        field['postObjectFunctionPara'].forEach(function (paraName: string) {
          postObjectPara.push(_this.getFieldValue(paraName, rowData, formGroup));
        });
      }
      postObject = this.runFunction(postObjectPara, field['postObjectFunction']);
    }
    return postObject;
  }

  formatNumber(number: number, addSymbol: string = '', position: string = 'left|right', locale: string = 'en-GB',decimalPoint:number = 0) {
    if (position === 'left') {
      return addSymbol + Number(number).toLocaleString(locale,{maximumFractionDigits:decimalPoint,minimumFractionDigits:decimalPoint});
    } else {
      return Number(number).toLocaleString(locale,{maximumFractionDigits:decimalPoint,minimumFractionDigits:decimalPoint}) + addSymbol;
    }

  }
  formatNumberWithoutComma(number: any,decimalPoint:number = 0) {
    if(typeof number === 'string') {
      number = parseFloat(number);
    }
    return Number(number).toFixed(decimalPoint);
   }


  getValueFromSection(section, fieldName: string) {
    return section[fieldName];
  }

  getFullName(userMasterObject) {
    let fullname = userMasterObject['firstName'] + ' ';
    if (userMasterObject['middleName'] !== undefined && userMasterObject['middleName'] !== null && userMasterObject['middleName'].length > 0) {
      fullname += userMasterObject['middleName'] + ' ';
    }
    if (userMasterObject['lastName'] !== undefined && userMasterObject['middleName'] !== null && userMasterObject['lastName'].length > 0) {
      fullname += userMasterObject['lastName'];
    }
    return fullname;
  }

  getCondtionFilterString(cond) {
    let filter = '';
    if (cond === 'is' || cond === 'equalsto') {
      filter = 'equals';
    } else if (cond === 'isNot' || cond === 'notequalsto') {
      filter = 'notEquals'
    } else if (cond === 'contains') {
      filter = 'contains';
    } else if (cond === 'startWith') {
      filter = 'startsWith'
    } else if (cond === 'endWith') {
      filter = 'endsWith'
    } else if (cond === 'lessthan') {
      filter = 'lt'
    } else if (cond === 'lessthanequals') {
      filter = 'lte'
    } else if (cond === 'greaterthan') {
      filter = 'gt'
    } else if (cond === 'greaterthanequals') {
      filter = 'gte'
    } else if (cond === 'between') {
      filter = 'between'
    } else {
      filter = 'contains';
    }
    return filter;
  }

  applyFilters(table: Table, grid: Grid, gridFilter: any) {   //applying filters from prefresences
    let _this = this;
    table.filters = {};
    let filters: any[] = [];
    if (gridFilter.length > 0) {
      gridFilter.forEach(function (filter: Filter) {
        filters.push(filter.copy());
        let valuetofilter;
        if (filter.getType() === 'L' || filter.getType() === 'B') {
          valuetofilter = filter.getFirstValue()['value'];
        } else if (filter.getType() === 'N' || filter.getType() === 'T' || filter.getType() === 'P' || filter.getType() === 'D' || filter.getType() === 'LB') {
          if (_this.getCondtionFilterString(filter.getCondition()) === 'between') {
            valuetofilter = filter.getCombineValue();
          } else {
            valuetofilter = filter.getFirstValue();
          }
          if (filter.getType() === 'N' && _this.getCondtionFilterString(filter.getCondition()) !== 'between') {
            valuetofilter = parseInt(valuetofilter);
          }
        }
        _this.filterUpdates(valuetofilter, filter.getColumnName(), _this.getCondtionFilterString(filter.getCondition()), filter, table, grid);
      });
    } else {
      table.filters = {};
      table.filteredValue = null;
    }
  }

  filterUpdates(firstValue, column, filtercond, filter: Filter, table: Table, grid: Grid) {
    let _this = this;
    if (filter.getType() === 'D') {
      if (Object.keys(firstValue).includes('firstValue')) {
        firstValue['firstValue'] = this.getDateWithFormat(column, firstValue['firstValue'], grid);
        firstValue['secondValue'] = this.getDateWithFormat(column, firstValue['secondValue'], grid);
      } else {
        firstValue = this.getDateWithFormat(column, firstValue, grid);
      }
    }
    table.filter(firstValue, column, filtercond);
    table.onFilter.subscribe(function (value) {
    })
  }

  getDateWithFormat(columnName: any, date: any, grid: Grid) {
    let format = this.getDateFormat(grid.getColumn().get(columnName));
    format = format.replace('DD', 'dd');
    format = format.replace('YYYY', 'yyyy');
    var formatted = this.datePipe.transform(date, format);
    return formatted;
  }

  getDateFormat(col: Tcolumn) {
    let format = environment.dateFormat;
    if (col.getFromExtra('date')['format'] !== undefined) {
      format = col.getFromExtra('date')['format'];
    }
    return format.toString().toUpperCase();
  }

  getFormattedDate(value, format:string = environment.dateFormat) {
    return this.datePipe.transform(value, format);
  }

  getDurationString(value:any) {
    let hours: number = Math.floor(value / 60);
    let minutes: number = (value - hours * 60);
    let str = '';
    if(hours > 0){
      if(hours < 10) {
        str+='0'+hours;
      } else {
        str+= hours;
      }
      str+=' Minutes ';
    }
    if(minutes < 10) {
      str+='0'+minutes;
    } else {
      str+= minutes;
    }
    str+=' Seconds';
    return str;
  }

  createFilters() {
    FilterUtils['gte'] = function gte(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value >= filter) {
        return true;
      }
      return false;
    };
    FilterUtils['gteD'] = function gte(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value >= filter) {
        return true;
      }
      return false;
    };
    FilterUtils['lte'] = function lte(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value <= filter) {
        return true;
      }
      return false;
    };
    FilterUtils['between'] = function between(value: any, filter: any): boolean {
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (value >= parseInt(filter['firstValue']) && value <= parseInt(filter['secondValue'])) {
        return true;
      }
      return false;
    };
    FilterUtils['startsWithOwn'] = function startsWithOwn(value: String, filter: any): boolean {
      let sub = value.substr(0, filter.length);
      if (filter === undefined || filter === null) {
        return false;
      }
      if (value === undefined || value === null || value.length === 0) {
        return false;
      }
      if (sub === filter) {
        return true;
      }
      return false;
    };
  }

  applySorting(sorts: any[], table: Table, primaryKey: string) {  //applying sorting from prefrences
    let _this = this;
    if (sorts.length > 0) {
      let meta = [];
      sorts.forEach(function (sorting) {
        meta.push({field: sorting.columnName, order: _this.getSortOrder(sorting.sortValue)})
      });
      table.multiSortMeta = meta;
      table.sortMultiple();
    } else {
      table.sortField = primaryKey;
      table.sortOrder = 1;
      table.sortSingle();
    }
  }

  getSortOrder(order) {
    if (order === 'asc') {
      return 1;
    }
    return -1;
  }

  getObligationKeyLable(key) {
    if (key === 'DELIVERY_STARTED') {
      return 'Delivery Started';
    } else if (key === 'DELIVERY_COMPLETED') {
      return 'Delivery Completed';
    } else if (key === 'PLANNED') {
      return 'Planned';
    } else if (key === 'UNPLANNED') {
      return 'Unplanned';
    } else if (key === 'ACTUALIZED') {
      return 'Actualized';
    } else if (key === 'SETTLED') {
      return 'Settled';
    } else if (key === 'STOCKED') {
      return 'Stocked';
    } else if (key === 'FX_ALLOCATED') {
      return 'FX Allocated';
    } else if (key === 'PRICED') {
      return 'Priced';
    } else if (key === 'PARTIALLY_PLANNED') {
      return 'Partially Planned';
    }
  }

  getObligationStateString(value: any) {
    let str: string = '';
    let _this = this;
    Object.keys(value).forEach(function (key) {
      if (str.length === 0) {
        if (value[key]) {
          str += _this.getObligationKeyLable(key);
        }
      } else {
        if (value[key]) {
          str += '/' + _this.getObligationKeyLable(key);
        }
      }
    });
    return str;
  }

  downloadPost(url: string, data: any) {
    return ajax({
      url: url,
      method: 'POST',
      responseType: 'blob',
      body: data
    });
  }


  getHttpErrorMessage(error: HttpErrorResponse, screenName?: string, newRecord: boolean = false) {
    let message: string = '';
    if(Object.keys(error.error).length > 0 && Object.keys(error.error).includes('status')) {
      message = error.error['status'];
    }else if(Object.keys(error.error).length > 0 && Object.keys(error.error).includes('errorCode')) {
      message = error.error['errorCode'];
    }
    else if(Object.keys(error.error).length > 0 && Object.keys(JSON.parse(error.error)).includes('status')) {
      message = JSON.parse(error.error)['status'];
    } else if (error.status === 400 && error.error !== undefined && error.error !== null) {
      if (error.error.toString().includes('org.hibernate.exception.ConstraintViolationException')) {
        message = 'Record already exists.';
      }
      if (error.error.toString().includes('Duplicate entry')) {
        message = error.error.toString().split('for')[0];
      } else {
        message = newRecord ? 'Failed to create new ' + screenName + '.' : 'Failed to update ' + screenName + '.';
      }
    } else {
      message = error.message;
    }
    return message;
  }

  getSafeHtml(stringHtml) {
    return this.sanitizer.bypassSecurityTrustHtml(stringHtml);
  }

  getSafeUrl(url)  {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  calculateTotalContractQuantity(quantity, periodicity, startDate, endDate) {
    if (quantity !== undefined && quantity !== '' && periodicity !== undefined && periodicity !== '') {
      if (periodicity.toLowerCase() === 'fixed') {
        return quantity;
      } else {
        if (endDate !== '' && startDate !== '') {
          if (periodicity.toLowerCase() === 'daily') {
            return quantity * this.numberOfDaysBetween(new Date(startDate), new Date(endDate));
          } else if (periodicity.toLowerCase() === 'weekly') {
            return quantity * this.numberOfWeeks(new Date(startDate), new Date(endDate));
          } else if (periodicity.toLowerCase() === 'monthly') {
            return quantity * this.numberOfMonths(new Date(startDate), new Date(endDate));
          } else if (periodicity.toLowerCase() === 'quarterly') {
            return quantity * this.numberOfQuaters(new Date(startDate), new Date(endDate));
          } else if (periodicity.toLowerCase() === 'yearly') {
            return quantity * this.numberOfYears(new Date(startDate), new Date(endDate));
          } else if (periodicity.toLowerCase().replace(new RegExp(' ', 'g'), '') === 'halfyearly') {
            return quantity * (this.numberOfYears(new Date(startDate), new Date(endDate)) * 2);
          } else {
            return 0;
          }
        }
      }
    }
    return 0;
  }

  calculateDeliveryStartDate(selectedDate, periodicity) {
    if (periodicity.toLowerCase() === 'fixed') {
      return selectedDate;
    } else if (periodicity.toLowerCase() === 'daily') {
      return selectedDate;
    } else if (periodicity.toLowerCase() === 'weekly') {
      let date = new Date(selectedDate.toString());
      if (date.toString().split(' ')[0] !== 'Sun') {
        date.setDate(date.getDate() - (date.getDay() + 6) % 7);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      }
      return selectedDate;
    } else if (periodicity.toLowerCase() === 'monthly') {
      let date = new Date(selectedDate.toString());
      date.setDate(1);
      return date;
    } else if (periodicity.toLowerCase() === 'quarterly') {
      let date = new Date(selectedDate.toString());
      let quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter === 1) {
        return new Date(date.getFullYear(), 0, 1);
      } else if (quarter === 2) {
        return new Date(date.getFullYear(), 3, 1);
      } else if (quarter === 3) {
        return new Date(date.getFullYear(), 6, 1);
      } else if (quarter === 4) {
        return new Date(date.getFullYear(), 9, 1);
      }
    } else if (periodicity.toLowerCase() === 'yearly') {
      let date = new Date(selectedDate.toString());
      date.setDate(1);
      date.setMonth(0);
      return date;
    } else if (periodicity.toLowerCase().replace(new RegExp(' ', 'g'), '') === 'halfyearly') {
      return selectedDate;
    }
  }

  private nextDayFromDate(date:Date,dayCode:number = 0) {
    let arr = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    let dateDay = date.toString().split(' ')[0];
    return this.addDaysInDate(date,Math.abs(arr.indexOf(dateDay) - dayCode));
  }

  calculateDeliveryEndDate(selectedDate:any, periodicity) {

    if (periodicity.toLowerCase() === 'fixed') {
      return selectedDate;
    } else if (periodicity.toLowerCase() === 'daily') {
      return selectedDate;
    } else if (periodicity.toLowerCase() === 'weekly') {
      let date = new Date(selectedDate.toString());
      if (date.toString().split(' ')[0] !== 'Sat') {
        return this.nextDayFromDate(date,6);
      }
      return selectedDate;
    } else if (periodicity.toLowerCase() === 'monthly') {
      let date = new Date(selectedDate.toString());
      date.setMonth(date.getMonth() + 1);
      date.setDate(0);
      return date;
    } else if (periodicity.toLowerCase() === 'quarterly') {
      let date = new Date(selectedDate.toString());
      let quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter === 1) {
        return new Date(date.getFullYear(), 3, 0);
      } else if (quarter === 2) {
        return new Date(date.getFullYear(), 6, 0);
      } else if (quarter === 3) {
        return new Date(date.getFullYear(), 9, 0);
      } else if (quarter === 4) {
        return new Date(date.getFullYear(), 12, 0);
      }
    } else if (periodicity.toLowerCase() === 'yearly') {
      let date = new Date(selectedDate.toString());
      return new Date(date.getFullYear(),12,0);
    } else if (periodicity.toLowerCase().replace(new RegExp(' ', 'g'), '') === 'halfyearly') {
      return selectedDate;
    }
  }

  numberOfDaysBetween(date1, date2) {
    let differenceInDays = 0;
    if (date1 !== null && date2 !== null) {
      let differenceInTime = new Date(date2.toString()).getTime() - new Date(date1.toString()).getTime();
      differenceInDays = differenceInTime / (1000 * 3600 * 24);
      return Math.ceil(differenceInDays) + 1;
    }
  }

  numberOfWeeks(date1: Date, date2: Date) {
    return Math.ceil(this.numberOfDaysBetween(date1, date2) / 7);
  }

  numberOfQuaters(date1: Date, date2: Date) {
    if (this.numberOfMonths(date1, date2) <= 3) {
      return 1;
    }
    return Math.ceil(this.numberOfMonths(date1, date2) / 3);
  }

  numberOfYears(date1: Date, date2: Date) {
    if (date2.getFullYear() - date1.getFullYear() < 1)
      return 1;
    return Math.abs(date2.getFullYear() - date1.getFullYear()) + 1;
  }

  numberOfMonths(date1: Date, date2: Date) {
    let months;
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return Math.abs(months) + 1;
  }

  getRowValueFromTcolumn(rowData, col: Tcolumn) {
    if (col.getColumnType() !== undefined && col.getColumnType() === 'D') {
      // return this.getFormattedDateTime(rowData[col.getField()], environment.dateFormat);
      return this.getFormattedDateTime(this.convertUTCtoDate(rowData[col.getField()],true), environment.dateFormat);
    }
    return (rowData[col.getField()] === undefined || rowData[col.getField()] === null || rowData[col.getField()].length === 0) ? '-' : this.checkAndGetValue(col, rowData, true);
  }

  getRowValue(rowData, col: any) {
    if (col.valueFunction !== undefined && col.valueFunction !== null) {
      return this.runFunction(rowData, col.valueFunction);
    }
    if (col.type !== undefined) {
      if (col.type === 'date') {
        return this.getFormattedDateTime(rowData[col.field], environment.dateFormat);
      } else if (col.type === 'list') {
        let list: any[] = col.list;
        let label = '';
        if (typeof (rowData[col.field]) === 'object') {
          label = rowData[col.field]['label'];
        }
        if (list !== undefined && list !== null && label.length <= 0) {
          list.forEach(function (value) {
            if (value['value'] === rowData[col.field]) {
              label = value['label'];
            }
          });
        }
        return label;
      }
    }
    return (rowData[col.field] === undefined || rowData[col.field] === null || rowData[col.field].length === 0) ? '-' : rowData[col.field];
  }

  splitDateAndType(value: string) {
    let obj = {count: 0, type: ''};
    if (value.toLowerCase().includes('week')) {
      obj.type = 'week';
    } else if (value.toLowerCase().includes('month')) {
      obj.type = 'month';
    }
    if (value.toLowerCase().includes('year')) {
      obj.type = 'year';
    }
    obj.count = parseInt(value.split(obj.type)[0]);
    return obj;
  }

  addListOTT(list: any[], label: string = 'Select', value = '') {
    if (list === undefined || list === null) {
      list = [];
    }
    list.splice(0, 0, new KeyValue(label, value));
    return list;
  }
  converttoLabelValue(data:any[],valueField:string) {
    let finalList: any[] = [];

    data.forEach(e => {
      finalList.push(e[valueField])
    })
    return finalList.filter((n,i)=>finalList.indexOf(n)===i);
  }

  createListFromObject(list: any[],label: string,value: string,addSelect:boolean = false,selectLabel = 'Select'){
    let finalList = [];
    if(addSelect) {
      finalList.push(new KeyValue(selectLabel,''));
    }
    list.forEach(function(obj: any) {
      finalList.push(new KeyValue(obj[label],obj[value]))
    });
    return finalList;
  }

  getListForCurrencyCode(code: string) {
    let list = [];
    if (Object.keys(this.currencyObject).includes(code)) {
      let codeData = this.currencyObject[code];
      list.push(new KeyValue(codeData['code'] + ' (' + codeData['symbol'] + ') ' + codeData['name'], code));
    }
    return list;
  }

  getPromise(url) {
    return this.http.get(url).toPromise().then(data => {
      return data;
    });
  }

  async fetchGetData(url) {
    return await this.getPromise(url);
  }

  public inputValidator(event: any, pattern, pattern2) {
    if (pattern !== undefined) {
      let value = event.target.value;
      if (value === undefined) {
        value = '';
      }
      let passed = pattern.test(value);
      if (!passed) {
        event.target.value = event.target.value.replace(pattern2, '');
      }
    }
  }

  generateRegex(includedList: any[], isNumericAllowed: boolean) {
    let reg = '^[A-Za-z';
    let reg1 = '[^A-Za-z';
    if (isNumericAllowed) {
      reg += '0-9';
      reg1 += '0-9';
    }
    includedList.forEach(function (ch) {
      if (ch === '\\') {
        reg += '\\';
      }
      reg += ch;
      reg1 += ch;
    });
    reg += ']+$';
    reg1 += ']';
    return {pattern1: new RegExp(reg), pattern2: new RegExp(reg1, 'g')};
  }

  updateInputValidation(event, includedCharcters: any[], isNumericAllowed: boolean = true) {
    let patterns = this.generateRegex(includedCharcters, isNumericAllowed);
    let pattern = patterns['pattern1'];
    let pattern2 = patterns['pattern2'];
    this.inputValidator(event, pattern, pattern2);

  }

  deepCopyArray(arrayList: any[]) {
    let newArrayList = [];
    newArrayList = Object.assign([], arrayList);
    return newArrayList;
  }

  deepCopyObject(objectToCopy: any) {
    let newObject = {};
    newObject = Object.assign({}, objectToCopy);
    return newObject;
  }
  getFormatedNumber(number, locale:string = 'en-US', format:string = '1.2-8'){
    if(number === null || number === undefined) {
      number = 0;
    }
    let result = '';
    if(number.toString().includes('.')) {
      result =  this.decimalPipe.transform(number,format);
    } else {
      result = Number(number).toLocaleString(locale);
    }
    return result;
  }

  getFormattedNumberWithoutComma(number:any, decimalPoints:number){
    if(number === null || number === undefined) {
      number = 0;
    }
    let result = '';
    if(number.toString().includes('.')) {
      result =  this.decimalPipe.transform(number,"1."+decimalPoints+'-'+decimalPoints);
    } else {
      result = Number(number).toLocaleString('en-US');
    }
    result = result.replace(/,/g,'');
    return result;
  }

  filterUpdatesForTable(firstValue, columnName, filtercond, table: Table) {
      table.filter(firstValue, columnName, filtercond);
      // table.onFilter.subscribe(function (value) {
      // })
    }



  getDefaultValue(map,key) {
    if(map !== undefined && map !== null && Object.keys(map).includes(key)) {
      return map[key];
    }
    return false;
  }

  convertNumberToWords(number:number,locale:string = 'en-US') {
    const toWords = new ToWords({
      localeCode: locale,
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
      }
    });
    return toWords.convert(number);
  }

  camelToTitleCase(str) {
    return str
        .replace(/[0-9]{2,}/g, match => ` ${match} `)
        .replace(/[^A-Z0-9][A-Z]/g, match => `${match[0]} ${match[1]}`)
        .replace(/[A-Z][A-Z][^A-Z0-9]/g, match => `${match[0]} ${match[1]}${match[2]}`)
        .replace(/[ ]{2,}/g, match => ' ')
        .replace(/\s./g, match => match.toUpperCase())
        .replace(/^./, match => match.toUpperCase())
        .trim();
  }

  convertMoneyToWordsByCurrencyCode(number:any,currencyCode:string = 'USD') {
      let isNegative:boolean = false;
      if(number < 0) {
        isNegative = true;
      }
      let num:any = Math.abs(number);
      let str:any= '';
      if(this.currencyJson === null || this.currencyJson === undefined) {
          this.updateCurrencyJson();
      }
      if(this.currencyJson !== null && this.currencyJson !== undefined) {
          let obj = this.currencyJson[currencyCode];
          if(obj !== null && obj !== undefined) {
              str = this.applyTitleCaseFormattingIfNotUpper(this.toWords(num,obj['code'], obj['subCurrencyUnit'],obj['code'],obj['subCurrencyUnitPlural']));
              if(isNegative) {
                str = "- "+str;
              }
              return str;
          }
      }
      str = this.applyTitleCaseFormattingIfNotUpper(this.convertNumberToWords(num));
      if(isNegative) {
        str = "- "+str;
      }
      return str;
  }

  private toWords(num,currency,subUnit,currencyPlural,subCurrenyPlural) {
    let options = {
      localeCode: 'en-US',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          name: currency,
          plural: currencyPlural,
          symbol: '$',
          fractionalUnit: {
            name: subUnit,
            plural: subCurrenyPlural,
            symbol: '',
          },
        }
      }
    };
    let toWord =  new ToWords(options);
    return toWord.convert(num);
  }

  private updateCurrencyJson() {
        let _this = this;
      this.getJSONByFile('currency.json').subscribe((next) => {
      _this.currencyJson = next;
    });
  }


  addDaysInDate(date:Date,daysToAdd:number) {
    let finalDate = date;
    finalDate.setDate(date.getDate() + daysToAdd);
    return finalDate;
  }

  substractDaysInDate(date:Date,daysToSubstract:number) {
    let finalDate = date;
    finalDate.setDate(date.getDate() - daysToSubstract);
    return finalDate;
  }

  addYearsInDate(date:Date,yearsToAdd:number) {
    let finalDate = date;
    finalDate.setFullYear(date.getFullYear() + yearsToAdd);
    return finalDate;
  }

  addMonthsInDate(date:Date,monthsToAdd:number) {
    let finalDate = date;
    finalDate.setMonth(date.getMonth() + monthsToAdd);
    return finalDate;
  }

  subtractMonthsInDate(date:Date,monthsToSubtract:number) {
    let finalDate = date;
    finalDate.setMonth(date.getMonth() - monthsToSubtract);
    return finalDate;
  }

    setNotificationViewStatus(notif: any) {
      let uuid = notif['uuid'];
      this.getFromUrl(environment.base_url + '/api/notification/v1/markread?tenantId='+this.getTenantId()+'&uuid='+uuid).subscribe((notif: any) => {
      });
    }

    sendNotfication(message:string,url:any = '',actionRequired:any = '',allowesRoles:any = '') {
      let payload = {};
      payload['generatedBy'] = this.getFromStorage('userName');
      payload['createdBy'] = this.getFromStorage('userName');
      payload['message'] = message;
      payload['url'] = url;
      payload['actionRequired'] = actionRequired;
      payload['allowedRoles'] = allowesRoles;
      payload['tenantId'] = this.getTenantId();
      payload['isRead'] = false;
      return this.post(environment.base_url + '/api/notification/v1/pushnotification?tenantId='+this.getTenantId(),payload);
    }

    getHttpClient() {
        return this.http;
    }
  downloadTextFile(data:any,filename) {
    this.fileSaverService.saveText(data,filename);
  }

    downloadFile(url:any,filename) {
      this.http.get(url, {responseType: 'blob',observe:'response'}).subscribe((res) => {
        this.fileSaverService.save(res.body,filename);
      });
    }

   getObligationObject(startDate,quantity,data, profitCenterConfig:any = null) {
    let object = null;
    object = {};
    //Common Attributes
     object['periodStartDate'] = this.convertDatetoUTC(startDate);
    // object['periodEndDate'] = this.convertDatetoUTC(this.calculateDeliveryEndDate(data['quantityPeriodicity'].toLowerCase() === 'fixed'?data['periodEndDate']:startDate, data['quantityPeriodicity']));
    object['periodEndDate'] = (this.calculateDeliveryEndDate(data['quantityPeriodicity'].toLowerCase() === 'fixed'?data['periodEndDate']:startDate, data['quantityPeriodicity']));
    object['packageType'] = data['packageType'];
    object['shipDelMonth'] = this.getFormattedDate(object['periodEndDate'],'MMMM-yy');
    //Attributes based on condition
    if(data['packageType'] === 'Bulk') {
      object['noOfUnits'] = 1;
      object['quantity'] = data['quantity'];
      object['package'] = '';
    } else if(data['packageType'] === 'Container'){
      object['noOfUnits'] = data['externalPackageUnit'];
      object['quantity'] = quantity;
      object['package'] = data['externalPackage'];
    } else if(data['packageType'] === 'Unit'){
      object['noOfUnits'] = data['internalPackageUnit'];
      object['quantity'] = quantity;
      object['package'] = data['internalPackage'];
    }
    object['uuid'] = '';
    return JSON.parse(JSON.stringify(object));
  }

  public convertDatetoUTC(dateToConvert:any,correctTimeInfoIfNeeded:boolean = false){
    if(typeof dateToConvert == "string") {
      dateToConvert = moment(dateToConvert).toDate();
    }
    let date:Date = new Date(dateToConvert)
    if(correctTimeInfoIfNeeded) {
      date = this.patchTimeInfo(date);
    }
    let dt = this.getFormattedDateTime(date,'yyyy-MM-ddTHH:mm:ss',false);
    return dt.toString().replace('Z', '');
  }


  public fixDateForDayIssue(date) {
    return this.convertDatetoUTC(date);
  }

  public convertUTCtoDate(datetoconvert,correctTimeInfoIfNeeded:boolean = false){
    if (datetoconvert !== null && datetoconvert !== undefined && datetoconvert.toString().length > 0){
      var date =  moment(datetoconvert).toDate();
      if (correctTimeInfoIfNeeded) {
        date = this.patchTimeInfo(date);
      }
      return date;
    }
    return '';
  }

  public getISOString(datetoconvert){
    if (datetoconvert !== null && datetoconvert !== undefined && datetoconvert.toString().length > 0 && datetoconvert.toString() !== 'Invalid Date'){
      return moment(datetoconvert).toDate().toISOString();
    }
    return datetoconvert;
  }

  public getLocalDateString(datetoconvert){
    if (datetoconvert !== null && datetoconvert !== undefined && datetoconvert.toString().length > 0 && datetoconvert.toString() !== 'Invalid Date'){
      return new Date(moment(datetoconvert).toDate().toLocaleDateString('en-CA'));
    }
    return datetoconvert;
  }
  private patchTimeInfo(date:Date) {
    let currentDate = moment().toDate();
    date.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
    return date;
  }

  public convertDatetoLocaltimzoeDate(date){
    if(date !== null && date !== undefined ){
      if(date.toString().charAt(date.toString().length - 1) !== 'Z'){
        date = date.toString().concat('Z');
      }
      return new Date(date).toLocaleString();
    }
    return '';
  }

  convertJsonIntoTcolumnsArray(list:any[]= []) {
    let columns:Tcolumn[] =[];
    list.forEach(function (obj:any,index:number) {
      if(obj !== null && obj !== undefined) {
        if((obj['filterColumn'] == null || obj['filterColumn'] == undefined)) {

          if (obj['type'] !== null && obj['type'] !== undefined && obj['type'].length > 0) {
            if (obj['type'] === 'list') {
              columns.push(new Tcolumn(obj['field'], obj['header'], 'L', index + 1, true, obj['list']));
            } else if (obj['type'] === 'date') {
              columns.push(new Tcolumn(obj['field'], obj['header'], 'D', index + 1));
            }
          } else {
            columns.push(new Tcolumn(obj['field'], obj['header'], 'T', index + 1));
          }
        } else {
          columns.push(obj['filterColumn'])
        }
      }
    })
    return columns;
  }

    getFilterApiPayload(value: Filter[] =[]) {
      let filterObjPayload = [{
        fieldName: 'tenantId',
        condition: 'equals',
        value: this.getTenantId(),
        secondValue: ''
      }];
      value.forEach((filter:any) =>{
        filterObjPayload.push({
              fieldName: filter['columnName'],
              condition: filter['condition'],
              value:this.getValueForFilterPayload(filter),
          secondValue: filter['secondValue']
            })});
      return filterObjPayload;
    }

  getValueForFilterPayload(filter: Filter) {
    if (filter['field'] !== undefined && filter['field'] !== null && filter['field']['columnType'] === 'D') {
      return filter['firstValue'];
    }
    if (filter['firstValue'] === 0 && filter['columnName'] === 'tradeTransactionType') {
      return 'BUY';
    }
    if (filter['firstValue'] === 1 && filter['columnName'] === 'tradeTransactionType') {
      return 'SELL';
    }
    return filter['firstValue'];
  }

  getDateForOutput(date: any) {
    if (date !== undefined && date !== null && date.toString() !== 'Invalid Date') {
      return this.datePipe.transform(date, environment.dateFormat);
    }
  }

  getEncodedURI(string) {
    return btoa(string);
  }


  getContextMenu(approvalWorkflowObject: any, callbackfunction: Function, enableAll: boolean = false, disableAll: boolean = false) {
    let menuItems: MenuItem[] = [];
    if (approvalWorkflowObject !== null && approvalWorkflowObject !== undefined) {
      let currentStep = approvalWorkflowObject.currentStep;
      let step: any[] = approvalWorkflowObject.workflowSteps.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence));
      step.forEach(steps => {
        let keys: any[] = Object.keys(steps.desiredEndStatus);
        let sublist: Button[] = [];
        for (let i in keys) {
          sublist.push(new Button(keys[i], 'F', '', callbackfunction, '', 'left', {
            step: steps,
            pressed: keys[i]
          }, undefined, false));
        }
        menuItems.push(this.getMenuItem(new Button(steps.actionName, 'F', '', sublist.length > 1 ? undefined : callbackfunction, undefined, undefined, undefined, undefined, disableAll ? true : (steps['updateStatus'].toLowerCase() === 'pending' ? (enableAll ? false : !(currentStep + 1 === steps.sequence)) : true), sublist.length > 1 ? sublist : undefined), steps, approvalWorkflowObject));
      });
    }
    return menuItems;
  }

  getMenuItem(button: Button, step, approvalWorkflowObject) {
    let _this = this;
    let items: MenuItem[] = [];
    if (button.sublist !== null && button.sublist !== undefined && button.sublist.length > 0) {
      button.sublist.forEach((button1: Button) => {
        items.push(_this.getMenuItem(button1, step, approvalWorkflowObject));
      });
    }
    let menuItem: MenuItem = {
      label: button.title,
      disabled: button.disabled,
      items: items.length === 0 ? null : items,
      command: (event) => {
        _this.runFunction({
          step: step,
          workflow: approvalWorkflowObject,
          buttonName: button.title,
          saveUrl: '',
          extra: button.extra
        }, button.onClick);
      }
    };
    return menuItem;
  }

  getDateWithLocalTime(date) {
    let currentDate = new Date();
    date.setHours(currentDate.getHours());
    date.setMinutes(currentDate.getMinutes());
    date.setSeconds(currentDate.getSeconds());
    return date;
  }

  getMenuItemFromButton(button: Button) {
    let _this = this;
    let items: MenuItem[] = [];
    if (button.sublist !== null && button.sublist !== undefined && button.sublist.length > 0) {
      button.sublist.forEach((button1: Button) => {
        items.push(_this.getMenuItemFromButton(button1));
      });
    }
    let menuItem: MenuItem = {
      label: button.title,
      disabled: button.disabled,
      items: items.length === 0 ? null : items,
      command: (event) => {
        let newData = _this.runFunction({
          saveUrl: '',
          extra: button.extra
        }, button.onClick);
      }
    };
    return menuItem;
  }

  getColValue(rowData, col: any) {
    if (col.valueFunction !== undefined && col.valueFunction !== null) {
      return this.runFunction(rowData, col.valueFunction);
    }
    if (col.type !== undefined && col.type === 'date') {
      return this.getDateForOutput(rowData[col.field]);
    }
    if (col.numberFormat !== undefined && col.numberFormat === true) {
      return this.getFormatedNumber(rowData[col.field]);
    }
    return (rowData[col.field] === undefined || rowData[col.field] === null || rowData[col.field].length === 0) ? '-' : rowData[col.field];
  }

}
