import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {ConfirmationService, MessageService, SelectItem} from 'primeng';
import {BreadcrumbService} from '../../Components/ctrm-breadcrumb/breadcrumb.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {messages} from '../../services/common/messages';
import {ExcelServiceService} from '../../services/excel-service.service';
import {Tcolumn} from '../../grid/tcolumn.model';
import {Filter} from '../../grid/preference/filter/filter.model';
import {HttpErrorResponse} from '@angular/common/http';
import {AccessPolicyService} from '../../services/accesspolicy.service';

@Component({
  selector: 'app-invoices-and-payments',
  templateUrl: './invoices-and-payments.component.html',
  styleUrls: ['./invoices-and-payments.component.css']
})
export class InvoicesAndPaymentsComponent implements OnInit {

  sellInvoice: number = 0;
  purchaseInvoice: number = 0;
  quantityRoundOff:number=3;
  loading: boolean = false;
  priceRoundOff:number=2;
  filterArray: any[] = ['SELL', 'PURCHASE'];
  invoiceCols: any[];
  invoiceData: any[] = [];
  approvedData: any[] = [];
  selectedData: any[] = [];
  tabOptions: SelectItem[] = [];
  callApiFunc:Function;
  unpostNote: any = '';
  displayAudit: boolean = false;
  tabValue: string;
  tradeId: any = '';
  unpostModal: boolean = false;
  filteredTabValue:any = '';
  display: boolean = false;
  buySellHeight: string;
  reset: boolean = false;
  expandedRows = {};
  planTableHeight: string = '';
  unpostApiFunction:Function;
  applyingFilter: boolean = false;
  draftForm: boolean = false;
  mode: boolean = true;
  canEdit: boolean = false;
  approved: boolean = false;
  draftData: any[] = [];
  invoiceLineItemList: any[] = [];
  operationDisplay: boolean = false;
  baseURL: string = environment.base_url;
  columns:Tcolumn[] =[];
  totalElements: number = 0;
  pageSize:number = 0;
  filters:Filter[] = [];
  queryFilter:Filter[] = [];
  currentPageNumber:number = 0;
  totalPages:number = 0;
  preFilters: Filter[] = [];
  displayidinfo:boolean = false;
  infoRowdata:any;
  infotradeId:string ='';
  detailsOf:string = '';

  @ViewChild('planTable', {static: true}) planTable;
  isRefreshing: boolean = false;
  private isFiltered:Boolean = false;
  private filteredPlannedObligationId:any ='';
  buttonValue: string='';
  unpostAccess:boolean = false;
  selectedButton: string='';
  constructor(public commonService: CommonService,private accessSerivce:AccessPolicyService,private messageService: MessageService,private route:ActivatedRoute,
              public breadCrumbService: BreadcrumbService, private router: Router,private excelService: ExcelServiceService,private confirmationService: ConfirmationService) {
  }

  ngOnInit(): void {
    this.unpostAccess = this.accessSerivce.canAccessWithCurrentRoute("Action","unpost");
    let tenantConfig = this.commonService.getFromStorage("tenantConfig");
    if(tenantConfig !== null && tenantConfig !== undefined) {
      let json = JSON.parse(tenantConfig);
      if(json['invoiceConfig'] !== null && json['invoiceConfig'] !== undefined) {
        this.canEdit = json['invoiceConfig']['edit'];
      }
    }
    let tenantConfiguration=JSON.parse(this.commonService.getFromStorage("tenantConfig"));
    if(tenantConfiguration && tenantConfiguration.roundingFormat){
      this.quantityRoundOff=tenantConfiguration.roundingFormat.quantityRounding;
      this.priceRoundOff=tenantConfiguration.roundingFormat.priceRounding;
    }
    else{
      this.quantityRoundOff=3;
      this.priceRoundOff=2;
    }
    this.breadCrumbService.makeBreadcrumbTo(this.commonService.FINANCE_MAP_KEY, '/invoices');
    this.tabOptions = [{label: 'Pending', value: 'pending'},
      {label: 'Approved', value: 'approved'},
      {label: 'Settled', value: 'settled'}
    ];
    this.tabValue = 'pending';
    this.invoiceCols = [
      {field: 'invoiceNumber', header: 'Invoice Number'},
      {field: 'invoiceDate', header: 'Invoice Date', type: 'date'},
      {field: 'paymentDueDate', header: 'Invoice Due Date', type: 'date'},
      {field: 'invoiceType', header: 'Invoice Type'},
      {field: 'tradeId', header: 'Trade Id'},
      {field: 'plannedObligationIds', header: 'Planned Obligation'},
      { field: "vesselName", header: "Vessel Name" },
      { field: "vesselId", header: "Voyage Number" },
      {field: 'counterparty', header: 'Counterparty'},
      {field: 'portOfLoading', header: 'Port of Loading'},
      {field: 'portOfDischarging', header: 'Port of Discharge'},
      {field: 'quantity', header: 'Quantity'},
      {field: 'total', header: 'Amount'},
      {field: 'attachmentName', header: 'Attachments'},
      {field: 'status', header: 'Status'},
      // {field: 'traderApprovalDate', header: 'Trader Approval Date', type: 'date'},
      // {field: 'financeApprovedDate', header: 'Finance Approval Date', type: 'date'},
    ];
    this.columns = this.commonService.convertJsonIntoTcolumnsArray(this.invoiceCols);

    let _this = this;
    this.callApiFunc = function(postbuttonapiobj) {
      if(_this.selectedData !== null && _this.selectedData !== undefined && _this.selectedData.length > 0) {
        let invoice = _this.selectedData[0];
        postbuttonapiobj['url'] = environment.base_url+'/api/invoice/v1/postinvoicetoexternalsystem?invoiceId='+invoice['invoiceNumber']+'&tenantId='+_this.commonService.getTenantId();
        postbuttonapiobj['type'] = 'get';
        postbuttonapiobj['payload'] = null;
        return postbuttonapiobj;
      }
      return postbuttonapiobj;
    }
    this.unpostApiFunction =  function(payload: any) {
      if(_this.selectedData !== null && _this.selectedData !== undefined && _this.selectedData.length > 0) {
        let invoice = _this.selectedData[0]
        payload['url'] = environment.base_url + '/api/invoice/v1/unpost?invoiceId='+ invoice['invoiceNumber'];
        payload['type'] = 'post';
        payload['payload'] = {
          note: _this.unpostNote
        };
      }
      return payload;
  };
    this.route.queryParams.subscribe(params => {
      if(params !== undefined && params !== null && Object.keys(params).length > 0) {
        let keys:any[] = Object.keys(params);
        if(keys.includes('plannedObligationId')) {
          let value = params['plannedObligationId'];
          if(value !== null && value !== undefined) {
            _this.isFiltered = true;
            _this.filteredPlannedObligationId = value;
            this.getDataByPlannedObligationId(true);
          }
        }
        if(keys.includes('value')) {
          let value = params['value'];
          if(value !== null && value !== undefined) {
            _this.queryFilter = [new Filter('Invoice Number','invoiceNumber','equals',value,'','T',this.columns[0])];
          }
        }
        if(keys.includes('mode')) {
          let mode = params['mode'];
          let invoiceNumber = params['invoiceNumber'];
          _this.isFiltered = false;
          _this.queryFilter = [new Filter('Invoice Number','invoiceNumber','equals',invoiceNumber,'')];
          _this.tabValue = mode;
        }
      }
    });
  }

  onTabChange(tabValue) {
    this.operationDisplay = false;
    this.filterArray = ['SELL', 'PURCHASE'];
    if(this.isFiltered  && this.filteredTabValue === tabValue['value']) {
      this.getDataByPlannedObligationId();
    } else {
      if (this.filters !== undefined && this.filters !== null && this.filters.length > 0) {
        this.onApplyFilter(this.filters, false);
      } else {
        this.getData();
      }
    }
    this.approved = tabValue['value'] === 'approved' && !this.canEdit;
  }

  getClassForHeading(index) {
    return index > 0 ? 'headerNormal' : 'noPipe';
  }

  //On CLICKING REFRESH BUTTON OF GRID TOOLBAR
  planRefresh() {
    this.operationDisplay = false;
    if(this.isFiltered  && this.filteredTabValue === this.tabValue) {
      this.getDataByPlannedObligationId();
    } else {
      if (this.filters !== undefined && this.filters !== null && this.filters.length > 0) {
        this.onApplyFilter(this.filters, false);
      } else {
        this.getData();
      }
    }
  }

  //On CLICKING PRIMARY FIELD FROM TABLE
  onPrimaryClick() {
    this.draftForm = true;
  }

  //CLOSING THE INVOICE FORM
  onDraftClose() {
    this.draftForm = false;
    this.selectedData = [];
    this.operationDisplay = false;
  }

  //TRIGGERS WHEN APPROVE BUTTON IS CLICKED IN BOTTOM TOOLBAR
  onApproval(appBy: string,buttonValue) {
    this.approved = true;
    if(buttonValue == "Submit") {
      this.approved = false;
    }
    this.selectedButton = buttonValue;
    this.draftForm = true;
    this.operationDisplay = false;
  }


  //TRIGGERS WHEN USER APPROVES THE SETTLE
  onSettled(val: any) {
    this.draftForm = false;
    this.approved = false;
    this.messageService.add({severity: 'info', summary: 'Success', detail: 'Invoice is settled'});
    this.planRefresh()
  }

  //ON PRESSING "SETTLE" BUTTON FROM BOTTOM BAR
  onSettleAction() {
    this.selectedButton = 'Settle'
    this.approved = true;
    this.draftForm = true;
  }

  //TRIGGERS WHEN USER APPROVES THE INVOICE
  onApproved(appFor: any) {
    this.planRefresh();
    this.messageService.add({severity: 'info', detail: 'Invoice approved successfully'});
  }

  //GET STYLE OF SELECTED FILTER
  getStyle(selected: string) {
    if (this.filterArray.includes(selected)) {
      return 'statShadow';
    } else {
      return '';
    }
  }

  // WHEN USER SELECTS FILTER(s) or COMBINATION OF FILTER FROM UI to see corresponding data
  selectFilter(filterName: string) {
    const elementsIndex = this.filterArray.findIndex(element => element == filterName);
    if (!this.filterArray.includes(filterName)) {
      this.filterArray.push(filterName);
    } else {
      if (this.filterArray.length > 1) {
        this.filterArray.splice(elementsIndex, 1);
      } else {
        this.filterArray = ['SELL', 'PURCHASE'];
      }
    }
    if(this.isFiltered && this.filteredTabValue === this.tabValue) {
      this.getDataByPlannedObligationId();
    } else {
      this.getData();
    }
  }

  getData() {
    //CLEAR INVOICE DATA FIRST BEFORE GETTING DATA
    let _this = this;
    this.isRefreshing = true;
    this.invoiceData = [];
    this.selectedData = [];
    let stage: string;
    if (this.tabValue === 'approved') {
      stage = 'POSTED,INVOICED';
    } else if (this.tabValue === 'pending') {
      stage = 'Initiated'
    } else {
      stage = 'Settled'
    }
    // CALL API HERE WITH STATUS (STAGE)
    this.commonService.getJSONByURL(environment.base_url + '/api/settlement/v1/getInvoiceByStage/' + stage+'?tenantId='+this.commonService.getFromStorage('tenantId')+'&page='+this.currentPageNumber+'&size=20').subscribe((next: any) => {
      // this.invoiceData = next['invoices'];
      // this.sellInvoice = next['sellCount'];
      // this.purchaseInvoice = next['purchaseCount'];
      // this.totalElements= next['totalRecords']
      this.invoiceData = next['content'];
      this.totalElements= next['totalElements']
      this.isRefreshing = false;
    });
  }

  getDataByPlannedObligationId(changeTab:boolean = false) {
    //CLEAR INVOICE DATA FIRST BEFORE GETTING DATA
    if(this.filteredPlannedObligationId !== null && this.filteredPlannedObligationId !== undefined && this.filteredPlannedObligationId !== '') {
      this.isRefreshing = true;
      this.invoiceData = [];
      this.selectedData = [];
      let _this = this;
      this.commonService.getJSONByURL(environment.base_url + '/api/settlement/v1/getInvoiceByStageByPlannedObligationId?tenantId='+this.commonService.getFromStorage('tenantId')+'&plannedObligationId='+this.filteredPlannedObligationId).subscribe((next: any) => {
        _this.invoiceData = next['invoices'];
        _this.sellInvoice = next['sellCount'];
        _this.purchaseInvoice = next['purchaseCount'];
        _this.isRefreshing = false;
        if(changeTab) {
          let row = _this.invoiceData[0];
          if (row !== null && row !== undefined) {
            if (row['status'].toLowerCase() === 'posted' || row['status'].toLowerCase() === 'invoiced') {
              this.tabValue = 'approved';
            } else if (row['status'].toLowerCase() === 'initiated') {
              this.tabValue = 'pending';
            } else if (row['status'].toLowerCase() === 'settled') {
              this.tabValue = 'settled';
            }
            this.filteredTabValue = this.tabValue;
          }
        }
      });
    }
  }

  //Get the value as per the Columns Type
  getColValue(rowData, col: any) {
    if (col.valueFunction !== undefined && col.valueFunction !== null) {
      return this.commonService.runFunction(rowData, col.valueFunction);
    }
    if (col.type !== undefined && col.type === 'date') {
      return this.commonService.getFormattedDateTime(this.commonService.convertUTCtoDate(rowData[col.field],true), environment.dateFormat)
    }
    return rowData[col.field];
  }

  // CHECK ROLE OF THE CURRENT LOGGED IN USER TO SHOW THE BUTTONS
  checkRole(role) {
    return this.accessSerivce.canAccessWithCurrentRoute('Action',role);
  }

  //TRIGGERS WHEN A ROW IS SELECTED
  onRowSelectionChange(rowData: any) {
    this.getbuttonNameForApprove();
    if (this.selectedData.length === 1) {
      if(this.selectedData[0] !== null && this.selectedData[0] !== undefined) {
        this.loadInvoiceForm(this.selectedData[0]['invoiceNumber'],this.selectedData[0]['invoiceType']);
      }
    }else{
      this.operationDisplay = false;
    }
  }

  private loadInvoiceForm(invoiceNumber:any,invoiceType:any) {
    let _this = this;
    let invoiceFormPayload = {
      invoiceNumber: invoiceNumber,
      invoiceType: invoiceType
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/settlement/v1/getinvoiceformdata?tenantId='+this.commonService.getFromStorage('tenantId'), invoiceFormPayload).subscribe((next: any) => {
      _this.draftData = next[0];
      _this.setMode(invoiceType);
      _this.operationDisplay = true;
      _this.invoiceLineItemList = next[0]['invoiceLineItemList']
    });
  }


  setMode(type: string) {
    if (type.toLowerCase() === 'purchase') {
      this.mode = false;
    } else {
      this.mode = true;
    }
  }

  @HostListener("window:resize", [])
  public onResize() {
    this.calculateHeight();
  }

  calculateHeight() {
    let offsetTop = this.planTable.el.nativeElement['offsetTop'];
    let offsetHeight = this.planTable.el.nativeElement['offsetParent']['offsetHeight'];
    this.planTableHeight = (offsetHeight - offsetTop) - 20 + 'px';
    if(this.getPagination()) {
      offsetHeight = offsetHeight - 30;
    }
    this.planTableHeight = (offsetHeight - offsetTop - 22) + 'px';
  }

  private continueDeleteInvoice(invoiceNumber:string,groupInvoice:boolean = false,onSuccess:Function) {
    let deleteInvoicePayload: {};
    deleteInvoicePayload = {
      type: this.selectedData[0]['invoiceType'],
      invoiceNumber: invoiceNumber,
      groupInvoice: groupInvoice
    };
    this.commonService.getJSONByObject(environment.base_url + '/api/invoice/v1/invoicevoid?tenantId='+this.commonService.getFromStorage('tenantId'), deleteInvoicePayload).subscribe(next => {
      onSuccess(next);
    }, error => {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Invoice',false),'error');
    })
  }

  //CALLING API TO DELETE INVOICE HERE
  deleteInvoice() {
    let  _this = this;
    let parentInvoiceNumber =  this.selectedData[0]['parentInvoiceNumber'];
    let onSuccess = (resp) => {
      if(resp['successInstance']) {
        var message = resp['status'];
        _this.messageService.add({
          severity: 'info',
          summary: message
        });
      }
      _this.planRefresh();
    }
    if(parentInvoiceNumber !== null && parentInvoiceNumber !== undefined && parentInvoiceNumber.length !== 0) {
      this.confirmationService.confirm({
        message: 'This invoice is part of a Group Invoice. Deleting this invoice will result in deleting all invoices in that Group.\nAre you sure that you want to delete this invoice?',
        accept: () => {
          this.continueDeleteInvoice(parentInvoiceNumber,true,onSuccess);
        }, reject: () => {
        }
      });
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to delete this invoice?',
        accept: () => {
          this.continueDeleteInvoice(this.selectedData[0]['invoiceNumber'],false,onSuccess);
        }, reject: () => {
        }
      });
    }
  }

  approvalDisable(role: string) {
    if (this.selectedData.length > 0) {
      if (role === 'trader') {
        if (this.selectedData[0]['traderApprovalDate'] !== null) {
          return true;
        } else {
          return false;
        }
      }
      if (role === 'finance')
        if (this.selectedData[0]['financeApprovedDate'] !== null) {
          return true;
        } else {
          return false;
        }
    }
  }

  goTo() {
    this.router.navigate(['/settlement']);
  }

  deleteBtnCheck() {
    return (this.tabValue == 'approved' && this.canEdit) || !(this.tabValue === 'approved' || this.tabValue === 'settled');
  }

  getAttachmentUrl(url: any) {
    return environment.base_url + url;
  }

  onClickExport() {
    if (this.selectedData !== undefined && this.selectedData.length > 0) {
      this.excelService.exportDashboardDataView(this.invoiceCols, this.invoiceData, 'Invoice_Settle', 'Invoice_Settle')
    } else {
      this.messageService.add({
        severity: 'info', summary: messages.no_row_selected['summary'],
        detail: messages.no_row_selected['message']
      });
    }
  }
  showToast(msg, severity: string = 'success') {
    this.messageService.add({
      severity: severity,
      summary: msg
    });
  }

  onApplyFilter(value: Filter[],resetInfo:boolean = true) {
    let _this = this;
    if(!this.isFiltered) {
      this.isRefreshing = true;
      this.invoiceData = [];
      this.selectedData = [];
      if (resetInfo) {
        this.currentPageNumber = 0;
      }
      if(this.queryFilter !== null && this.queryFilter !== undefined && this.queryFilter.length > 0) {
        value = this.queryFilter;
      }
      let payload = this.commonService.getFilterApiPayload(value);
      _this.queryFilter = [];
      if (payload.length > 1) {
        this.filters = value;
        let stage: string[] = [];
        if (this.tabValue === 'approved') {
          stage.push('POSTED');
          stage.push('INVOICED');
        } else if (this.tabValue === 'pending') {
          stage.push('Initiated');
        } else {
          stage.push('Settled');
        }
        payload.push({
          fieldName: 'status',
          condition: 'in',
          value: stage,
          secondValue: ''
        })
        let url = environment.base_url + '/api/invoice/v1/getinvoicebycriteria?tenantId=' + this.commonService.getTenantId() + '&page=' + this.currentPageNumber + '&size=20'
        this.commonService.post(url, payload).subscribe((next: any) => {
          _this.isRefreshing = false;
          _this.invoiceData = next['content'];
          _this.pageSize = next['pageable']['pageSize'];
          _this.totalElements = next['totalElements'];
          _this.totalPages = next['totalPages'];
          _this.calculateHeight();
        }, (error: HttpErrorResponse) => {
          _this.isRefreshing = false;
          _this.showToast(_this.commonService.getHttpErrorMessage(error, 'Invoices & Payments'), 'error');
        });
      } else {
        this.filters = [];
        this.getData();
      }
    }
  }

  closeaudittrail() {
    this.displayAudit = false;
  }

  onClickAuditButton(value: any) {
    this.displayAudit = true;
  }

  plannedObligationclick(value: any,rowData:any) {
    this.infotradeId = rowData.tradeId;
    let _this = this;
    this.commonService.getJSONByURL(environment.base_url + '/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?plannedObligationId=' + value+'&tenantId='+this.commonService.getFromStorage('tenantId')).subscribe(next => {
      _this.infotradeId = next['tradeId'];
      _this.infoRowdata = next;
      _this.detailsOf = 'plannedObligation';
      _this.displayidinfo = true;
    });
  }

  onClose(value: any) {
    this.displayidinfo = false;
  }

  tradeIdclick(value: any) {
    this.infoRowdata = {tradeId:value.tradeId};
    this.infotradeId = value.tradeId;
    this.detailsOf = 'tradeId';
    this.displayidinfo = true;
  }

  onErrorPostinginvoice(error: HttpErrorResponse) {
    let response = error.error['response'];
    if(response !== null && response !== undefined) {
      let errorMessage = 'Error while posting invoice : \n';
      if(response instanceof Array) {
        errorMessage = response.join('\n');
      }
      this.messageService.add({
        severity: 'error', summary: 'Error while posting invoice :',
        detail: errorMessage
      });
    } else {
      this.showToast(this.commonService.getHttpErrorMessage(error,'Invoice & Payment'),'error');
    }
  }

  onPostingSuccess(value: any) {
    let status = value['status'];
    let response:any[] = value['response'];
    if((response !== null && response !== undefined && Array.isArray(response) && response.length > 0) || status.toLowerCase().includes("error")) {
      this.showToast("Errors while post : \n"+response.join("\n"),'error');
    } else {
      this.showToast((status.length != 0)?status:"Invoice Posted Successfully.");
    }
    this.planRefresh();
  }

  openAttachment(attachmentUrl: string, event: MouseEvent) {
    event.preventDefault();
    window.open(attachmentUrl, "_blank");
  }
  plannedobligation(val:any){
    if(val){
        val = val.filter((el, i, a) => i === a.indexOf(el))
    }
    return val;
  }

  onUnpostDone(value:any) {
    let response:any[] = value['response'];
    if(response !== null && response !== undefined && Array.isArray(response) && response.length > 0) {
      this.showToast("Errors while unpost : \n"+response.join("\n"),'error');
    } else {
      this.showToast("Invoice Unposted Successfully",'success');
      this.unpostModal = false;
    }
    this.planRefresh();
  }

  onUnpostFail(error:HttpErrorResponse) {
      this.showToast(this.commonService.getHttpErrorMessage(error, "Invoice"), 'error');
  }


  getbuttonNameForApprove() {
    if(this.selectedData.length>0){
      if(this.selectedData[0]['status'].toLowerCase() !== 'posted'){
        this.buttonValue = 'Post Invoice';
      }else {
        this.buttonValue = 'Update';
      }
      return this.buttonValue;
    }
  }

  closeUnpostModal() {
    this.operationDisplay = true;
    this.unpostModal = false;
  }

  showUnpostNote() {
    this.operationDisplay = false;
    this.unpostNote = '';
    this.unpostModal = true;
  }

  onEditInvoice() {
    this.selectedButton = "Edit Invoice";
    this.draftForm = true;
    this.approved = false;
    this.operationDisplay = false;
  }

  onCloseBottom() {
    this.operationDisplay = !this.operationDisplay;
    this.selectedData = [];
  }

  onUpdate(value: any) {
    this.draftForm = false;
    this.operationDisplay = false;
    this.selectedData = [];
    this.selectedButton = '';
    this.planRefresh();
    this.messageService.add({severity: 'info', detail: 'Invoice Updated Successfully'});
  }

  getPagination() {
    return this.totalElements > this.pageSize;
  }

  onPageChange(value: any) {
    this.currentPageNumber = value['page'];
    this.planRefresh();
    this.getDataByPlannedObligationId(false);
  }

  private getObligationIdsForRefreshInvoice(invoiceType: string) {
    let list = this.invoiceLineItemList.map(item => {
        return {plannedObligationId: item['plannedObligationId'], splitSequenceNumber: item['splitNumber']}
      });
    let distinctList = [];
    list.forEach(item => {
      if(distinctList.filter(item1 => (item1.plannedObligationId === item.plannedObligationId && item1.splitSequenceNumber === item.splitSequenceNumber)).length ===0) {
        distinctList.push(item);
      }
    });
    let filteredList = distinctList.filter(item => item.plannedObligationId !== null && item.plannedObligationId !== undefined && item.plannedObligationId.length >  0);
    if(invoiceType.toLowerCase() == 'washout') {
      return [filteredList[0]];
    }
    return filteredList;
  }

  private getCashflowTypes() {
    let list = this.invoiceLineItemList.filter(item => item.cashflowType !== null && item.cashflowType !== undefined).map(item => {
      return item['cashflowType'].toLowerCase();
    });
    let distinctList = [];
    list.forEach(item => {
      if(distinctList.filter(item1 => (item1=== item)).length ===0) {
        distinctList.push(item);
      }
    });
    return distinctList;
  }

  private getCashflowIds(invoiceType: string) {
    let list = this.invoiceLineItemList.filter(item => item.cashflowId !== null && item.cashflowId !== undefined).map(item => {
      return item['cashflowId'];
    });
    let idList = this.invoiceLineItemList.filter(item => item.cashflowId.toLowerCase() === 'list').map(item => {
      return item['cashflowIdList'];
    });
    idList.forEach(item => {
      let splits = item.toString().split(",");
      list = list.concat(splits);
    })
    let distinctList = [];
    list.forEach(item => {
      if(distinctList.filter(item1 => (item1=== item)).length ===0) {
        distinctList.push(item);
      }
    });
    let excludedItem = ['advance','adjustment_amount','list'];
    let excludedList = distinctList.filter(item => !excludedItem.includes(item.toLowerCase()));
    if(invoiceType.toLowerCase() == 'washout') {
      return [excludedList[0]];
    }
    return excludedList;
  }

  private getInvoiceType(docbypass: boolean, docbypassCnDn: boolean) {
    let rawInvoiceType = '';
    let invoice = this.selectedData[0];
    let invoiceType = invoice['invoiceType'];
    let finalInvoiceType = invoice['finalInvoiceType']
    let fullInvoiceType = invoice['fullInvoiceType'].toLowerCase();
    if(invoiceType.toLowerCase().includes("advance")) {
      rawInvoiceType = "Advance";
    } else if(invoiceType.toLowerCase().includes("note")) {
      if(docbypassCnDn ) {
        rawInvoiceType = "Washout Docbypass CN/DN";
      } else if(fullInvoiceType.includes("cost")) {
        rawInvoiceType = "Adhoc Credit Note";
      } else if(fullInvoiceType.includes("charges")) {
        rawInvoiceType = "Adhoc Debit Note";
      } else if (fullInvoiceType.toLowerCase() === "washout invoice") {
        rawInvoiceType = 'Washout';
      } else if (fullInvoiceType.toLowerCase() === "circle invoice") {
        rawInvoiceType = 'Washout - Circle';
      } else if (fullInvoiceType.toLowerCase() === "bypass invoice") {
        rawInvoiceType = 'Washout - String';
      } else {
        if (finalInvoiceType.toLowerCase().includes('claim')) {
          rawInvoiceType = 'Outturn';
        } else if (finalInvoiceType.toLowerCase().includes('provisional')) {
          rawInvoiceType = 'Price Diff';
        }
      }
    } else {
      if(docbypass) {
        rawInvoiceType = "Washout - DocBypass";
      } else{
        rawInvoiceType = "Commercial";
      }
    }
    return rawInvoiceType;
  }

    refreshInvoice() {
      let _this = this;
      let plannedObligationId = this.selectedData[0]['plannedObligationId'];
      let invoiceNumber = this.selectedData[0]['invoiceNumber'];
      let docbypass= false;
      this.commonService.getJSONByURL(environment.base_url+'/api/plannedObligation/v1/getplannedobligationsbyplannedobligationid?tenantId=' +this.commonService.getFromStorage('tenantId')+'&plannedObligationId='+plannedObligationId).subscribe((data:any)=>{
            docbypass=(data.docByPassId != null && data.docByPassId != undefined && data.docByPassId.length>0)?true:false;
            var docbypassCnDn =(_this.selectedData[0]['invoiceType']=='Credit Note' || _this.selectedData[0]['invoiceType']=='Debit Note') && docbypass? true:false;
            var fullinvoicetype = _this.selectedData[0]['fullInvoiceType'];
            let charges = fullinvoicetype == 'Charges Invoice'
            let cost = fullinvoicetype == 'Cost Invoice'
            let invoiceType = this.getInvoiceType(docbypass,docbypassCnDn);
            let cashflowTypes = this.getCashflowTypes();
            let obligationIds = this.getObligationIdsForRefreshInvoice(invoiceType);
            let payload = {
              plannedObligationIds: obligationIds,
              tradeUuid: '',
              cashflowIds: this.getCashflowIds(invoiceType),
              invoiceType: invoiceType,
              tradeId: _this.selectedData[0]['tradeId'],
              advance :  this.selectedData[0]['invoiceType'].toLowerCase().includes("advance"),
              proforma : false,
              costOnly : cost,
              chargesOnly: charges,
              docbypass: docbypass,
              refreshing: true,
              invoiceNumber: invoiceNumber,
              docbypassCnDn: docbypassCnDn,
              attachCost : _this.commonService.getFromTenantConfig(false,"invoiceConfig","attachCost") ||  cashflowTypes.includes("cost"),
              attachCharges: _this.commonService.getFromTenantConfig(false, "invoiceConfig", "attachCharge") || cashflowTypes.includes("charges")
            };
            _this.confirmationService.confirm({
          message: 'Editing an invoice will update all current invoice values.\n Are you sure you want to continue ?',
          accept: () => {
            _this.draftForm = true;
            _this.loading = true;
            _this.approved = false;
            _this.selectedButton = 'Refresh Invoice';
            let onSuccess = (resp) => {
              _this.commonService.post(`${environment.base_url}/api/invoice/v2/getdraftinvoicepayload`,payload).subscribe((next: any) => {
                next['invoiceNumber'] = invoiceNumber;
                next['plannedObligationId'] = plannedObligationId;
                _this.mode = data['tradeTransactionType'] !== "BUY"
                _this.draftData = next;
                _this.loading = false;
              },(error:HttpErrorResponse) => {
                _this.draftForm = false;
                _this.operationDisplay = true;
                _this.planRefresh();
                _this.loading = false;
                _this.showToast(_this.commonService.getHttpErrorMessage(error,"Settlement"),'error');
              });
            }
            onSuccess(null);
          }, reject: () => {
          }
        });
      });
    }


  rejectInvoice( ) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reject the invoice ?',
      accept: () => {
        this.commonService.getJSONByURL(`${environment.base_url}/api/invoice/v2/rejectInvoice?invoiceNumber=${this.selectedData[0]['invoiceNumber']}`).subscribe(next => {
          this.showToast("Selected Invoices have been rejected")
          this.planRefresh();
        },(error:HttpErrorResponse) => {
          this.showToast(this.commonService.getHttpErrorMessage(error,'Failed to reject invoice'),'error');
        })
      }, reject: () => {
      }
    })
  }
}
